export default function(elClass, animClass) {
    if(!elClass || !animClass) return;
     const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
    }

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const el = entry.target;
                el.classList.add(animClass);
                observer.unobserve(el);
            }
        })
    }, options)

    const elements = document.querySelectorAll(elClass);
    elements.forEach(el => {
        observer.observe(el)
    });
}