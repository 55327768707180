<script>
import { scrollTo } from '../utils/helpers';
import ScrollToBottom from '../components/ScrollToBottom';
import {isMobile} from '../utils/helpers';

    export default {
        components: {
            ScrollToBottom
        },
        methods: {
            scrollToScreen: scrollTo,
            isMobile: isMobile
        }
    }
</script>