<template>
    <div class="container" id="group_of_risks" data-section="RiskGroupsFirstScreen">
      <div class="hidden-group" v-if="!isMobile()">
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
      </div>
      <LayoutFirst>
        <section class="content-move">
            <img src="../../assets/images/risk-1-character.png" alt="Вітроля" class="risk-first__character">
            <div class="risk__heading">
                <p>Мені приємна любов українців до вітрянки.</p>
                <p>Я стараюся заражати всіх, кого можу, але до моїх улюбленців належать<sup>2</sup>:</p>
            </div>
            <ul class="risk__list">
                <li class="risk__list-item bold--accent">малюки;</li>
                <li class="risk__list-item bold--accent">дошкільнята та школярі;</li>
                <li class="risk__list-item bold--accent">батьки маленьких дітей, які не хворіли;</li>
                <li class="risk__list-item bold--accent">усі дорослі, у кого немає імунітету.</li>
            </ul>
        </section>
        <div class="show-mobile">'
          <inline-svg class="pimple pimple-1-sm scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>
          <inline-svg class="pimple pimple-2-sm scar-small-mobile" :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-3-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-4-sm pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-5-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        </div>
        <ScrollToBottom @go-next-section="$emit('go-next-section', 'RiskGroupsSecondScreen')" />
      </LayoutFirst>
    </div>
</template>

<script>
import scrollToMixin from '../../mixins/scrollToMixin';
import LayoutFirst from '../../components/LayoutFirst';

    export default {
        name: "RiskGroupsFirstScreen",
        components: {
          LayoutFirst
        },
        mixins: [scrollToMixin]
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';
  .pimple {
    @media screen and (max-width: $mobile) {
      &-1-sm {
        right: vwm(18px);
        top: vwm(105px);
      }
      
      &-2-sm {
        right: vwm(39px);
        top: vwm(159px);
      }
      
      &-3-sm {
        right: vwm(90px);
        top: vwm(340px);
      }
      
      &-4-sm {
        right: vwm(99px);
        top: vwm(365px);
      }
      
      &-5-sm {
        right: vwm(65px);
        top: vwm(370px);
      }
    }
  }  
    .section__wrapper {
        @media screen and (max-width: $mobile) {
            padding-top: vwm(41px);
        }
    }
</style>