<template>
<div id="history" class="container" data-section="Footer">
    <HistoryFirstScreen/>
    <HistorySecondScreen/>
    <footer class="footer">
        <div class="footer__top">
            <picture>
                <source srcset="../assets/images/mobile/footer-mobile.svg"
                        media="(max-width: 480px)">
                <img src="../assets/images/footer.svg" alt="Самолікування може бути шкідливим для вашого здоров'я">
            </picture>
        </div>
        <div class="footer__middle">
            Реклама методу профілактики.
            <br>
            NP-UA-GVX-WCNT-200040. Дата підготовки матеріалів 16.07.2021.
            <br>
            Вакцинація можлива тільки на підставі рекомендацій Вашого лікаря. Перед проведенням вакцинації обов'язково проконсультуйтеся з лікарем. Для отримання більш детальної інформації про вакцинацію або захворювання зверніться до Вашого лікаря.
            <br>
            За додатковою інформацією звертайтеся до ТОВ ”ГлаксоСмітКляйн Фармасьютікалс Україна”: 02152, м. Київ, пр-т Павла Тичини, 1-В, тел.: (044) 585-51-85.
            <br>
            Матеріал призначено для користувачів, які знаходяться в Україні.

        </div>
        <div class="footer__bottom">
            <p class="footer__bottom-item">
                1. Varicella and herpes zoster vaccines: WHO position paper, June 2014 
                <a href="https://who.int/wer/2014/wer8925.pdf?ua=1" target="_blank" class="text-break">
                    who.int/wer/2014/wer8925.pdf?ua=1
                </a> 
                Last visited 16.07.2021
            </p>
            <p class="footer__bottom-item">
                2. CDC. Pink book. Chickenpox.
                <a href="https://www.cdc.gov/vaccines/pubs/pinkbook/downloads/varicella.pdf" target="_blank" class="text-break">
                    https://www.cdc.gov/vaccines/pubs/pinkbook/downloads/varicella.pdf
                </a> 
                доступно на 16.07.2021
            </p>
            <p class="footer__bottom-item">
                3. Календар профілактичних щеплень.
                <a href="https://moz.gov.ua/article/immunization/kalendar-profilaktichnih-scheplen" target="_blank" class="text-break">
                    https://moz.gov.ua/article/immunization/kalendar-profilaktichnih-scheplen
                </a> 
                Дата останнього перегляду 16.07.2021
            </p>
        </div>
    </footer>
</div>
</template>

<script>
import HistoryFirstScreen from '../views/history/HistoryFirstScreen';
import HistorySecondScreen from '../views/history/HistorySecondScreen';
    export default {
        name: "Footer",
        components: {
            HistoryFirstScreen,
            HistorySecondScreen
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        height: auto;
        flex-direction: column;
    }
</style>