<template>
  <div class="container" id="insta" data-section="FaqSecondScreen">
    <div class="hidden-group" v-if="!isMobile()">
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-small.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-large.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-regular.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-middle.svg')"
      ></inline-svg>
      <inline-svg
        :src="
          require('../../assets/images/pimples/default/pimple-large-mobile.svg')
        "
      ></inline-svg>
      <inline-svg
        :src="
          require('../../assets/images/pimples/default/pimple-middle-mobile.svg')
        "
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar-left.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar-right.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar-small.svg')"
      ></inline-svg>
      <inline-svg
        :src="
          require('../../assets/images/pimples/default/scar-small-mobile.svg')
        "
      ></inline-svg>
    </div>
    <LayoutFirst>
      <section class="content-move">
        <picture>
          <source
            srcset="../../assets/images/mobile/faq-2-character-mobile.png"
            media="(max-width: 480px)"
          />
          <img
            src="../../assets/images/faq-2-character-lg.png"
            alt="Вітроля"
            class="faq__character"
          />
        </picture>
        <div class="faq__content">
          <h5>
            Не знайшли відповідь на своє запитання про вітрянку?
          </h5>
          <h6>
            Раджу звернутись до лікаря для отримання інформації про хворобу та
            методи профілактики
          </h6>
          <!-- <span class="bold faq__content-title">
            Якщо ви досі це читаєте, то вам, мабуть, подобається стиль Вітролі.
          </span> -->
          <!-- <p class="faq__content-text">
            Хочете познайомитись ближче і при цьому не захворіти? Підписуйтесь на
            мій лайфстайл-блог в Інстаграмі! На вас чекає багато
            вітрянкогумору, цікавих постів та ще більше інформації про вітрянку.
          </p> -->
          <!-- <a
            href="https://instagram.com/vitrolya_lya_lya?igshid=1ia4fv304zs2s"
            @click="instClick()"
            target="_blank"
            class="faq__btn btn btn--large btn--instagram"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
            >
              <path
                d="M13.4964 8.99808C11.0175 8.99808 8.99454 11.0211 8.99454 13.5C8.99454 15.9789 11.0175 18.0019 13.4964 18.0019C15.9752 18.0019 17.9982 15.9789 17.9982 13.5C17.9982 11.0211 15.9752 8.99808 13.4964 8.99808ZM26.9985 13.5C26.9985 11.6357 27.0154 9.78837 26.9107 7.92748C26.806 5.76602 26.3129 3.84773 24.7324 2.26716C23.1485 0.683215 21.2336 0.19351 19.0722 0.0888138C17.2079 -0.0158819 15.3606 0.00100455 13.4997 0.00100455C11.6355 0.00100455 9.78819 -0.0158819 7.92734 0.0888138C5.76592 0.19351 3.84766 0.686593 2.26712 2.26716C0.683203 3.85111 0.193506 5.76602 0.0888122 7.92748C-0.0158816 9.79174 0.00100453 11.6391 0.00100453 13.5C0.00100453 15.3609 -0.0158816 17.2116 0.0888122 19.0725C0.193506 21.234 0.68658 23.1523 2.26712 24.7328C3.85103 26.3168 5.76592 26.8065 7.92734 26.9112C9.79156 27.0159 11.6389 26.999 13.4997 26.999C15.364 26.999 17.2113 27.0159 19.0722 26.9112C21.2336 26.8065 23.1518 26.3134 24.7324 24.7328C26.3163 23.1489 26.806 21.234 26.9107 19.0725C27.0188 17.2116 26.9985 15.3643 26.9985 13.5ZM13.4964 20.4268C9.66323 20.4268 6.56969 17.3332 6.56969 13.5C6.56969 9.66679 9.66323 6.57319 13.4964 6.57319C17.3295 6.57319 20.423 9.66679 20.423 13.5C20.423 17.3332 17.3295 20.4268 13.4964 20.4268ZM20.7067 7.90722C19.8118 7.90722 19.089 7.18448 19.089 6.2895C19.089 5.39452 19.8118 4.67179 20.7067 4.67179C21.6017 4.67179 22.3244 5.39452 22.3244 6.2895C22.3247 6.50202 22.283 6.7125 22.2018 6.90889C22.1206 7.10528 22.0015 7.28373 21.8512 7.434C21.7009 7.58427 21.5225 7.70342 21.3261 7.78462C21.1297 7.86583 20.9192 7.90749 20.7067 7.90722Z"
                fill="white"
              />
            </svg>
            <span>Перейти до мого Інстаграму</span>
          </a> -->
        </div>
      </section>
      <div class="show-mobile">
        <inline-svg
          class="pimple pimple-1-sm scar-left"
          :src="require('../../assets/images/pimples/default/scar-left.svg')"
        ></inline-svg>
        <inline-svg
          class="pimple pimple-2-sm scar-small-mobile"
          :src="
            require('../../assets/images/pimples/default/scar-small-mobile.svg')
          "
        ></inline-svg>
        <inline-svg
          class="pimple pimple-3-sm pimple-small"
          :src="require('../../assets/images/pimples/default/pimple-small.svg')"
        ></inline-svg>
        <inline-svg
          class="pimple pimple-4-sm pimple-middle"
          :src="
            require('../../assets/images/pimples/default/pimple-middle-mobile.svg')
          "
        ></inline-svg>
      </div>
      <ScrollToBottom @go-next-section="$emit('go-next-section', 'Footer')" />
    </LayoutFirst>
  </div>
</template>

<script>
import scrollToMixin from "../../mixins/scrollToMixin";
import LayoutFirst from "../../components/LayoutFirst";

export default {
  name: "FaqSecondScreen",
  components: {
    LayoutFirst,
  },
  mixins: [scrollToMixin],
  methods: {
    instClick() {
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "main_btn_instagram" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/vars";
@import "../../scss/mixin";
.pimple {
  @media screen and (max-width: $mobile) {
    &-1-sm {
      right: vwm(18px);
      top: vwm(105px);
    }

    &-2-sm {
      right: vwm(39px);
      top: vwm(159px);
    }

    &-3-sm {
      right: vwm(113px);
      bottom: vwm(57px);
    }

    &-4-sm {
      right: vwm(140px);
      bottom: vwm(40px);
    }
  }
}
</style>
