<template functional>
    <div class="section__wrapper">
        <div class="hide-mobile">

          <inline-svg class="pimple pimple-1 pimple-large" :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
          <inline-svg class="pimple pimple-2 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-3 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-4 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

          <inline-svg class="pimple pimple-5 scar-large" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>

          <inline-svg class="pimple pimple-6 scar-small" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
          <inline-svg class="pimple pimple-7 scar-regular" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>

          <inline-svg class="pimple pimple-8 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

          <inline-svg class="pimple pimple-9 scar-middle" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
          <inline-svg class="pimple pimple-10 scar-middle" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
          <inline-svg class="pimple pimple-11 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-12 scar-right" :src="require('../assets/images/pimples/default/scar-right.svg')"></inline-svg>

          <inline-svg class="pimple pimple-13 scar-large" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
          <inline-svg class="pimple pimple-14 scar-regular" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
          <inline-svg class="pimple pimple-15 scar-small" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
          <inline-svg class="pimple pimple-16 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

          <inline-svg class="pimple pimple-17 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-18 pimple-middle" :src="require('../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>

          <inline-svg class="pimple pimple-19 pimple-large" :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
          <inline-svg class="pimple pimple-20 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-21 pimple-regular" :src="require('../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
          <inline-svg class="pimple pimple-22 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-23 pimple-middle" :src="require('../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>

        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
      name: "LayoutThird"
    }
</script>

<style lang="scss" scoped>
@import '../scss/mixin';
  ::v-deep .pimple {
    &-1 {
      left: vw(125px);
      top: vw(25px);
    }
    
    &-2 {
      left: vw(130px);
      top: vw(100px);
    }
    
    &-3 {
      left: vw(95px);
      top: vw(165px);
    }
    
    &-4 {
      left: vw(150px);
      top: vw(159px);
    }
    
    &-5 {
      left: vw(435px);
      top: vw(69px);
    }
    
    &-6 {
      left: vw(944px);
      top: vw(349px);
    }
    
    &-7 {
      left: vw(1030px);
      top: vw(305px);
    }
    
    &-8 {
      right: vw(531px);
      bottom: vw(497px);
    } 
    
    &-9 {
      right: vw(170px);
      bottom: vw(584px);
    }
    
    &-10 {
      right: vw(263px);
      bottom: vw(498px);
    }

    &-11 {
      right: vw(376px);
      bottom: vw(483px);
    }
    
    &-12 {
      right: vw(138px);
      bottom: vw(455px);
    }
    
    &-13 {
      left: vw(268px);
      bottom: vw(202px);
    }
    
    &-14 {
      left: vw(367px);
      bottom: vw(187px);
    }
    
    &-15 {
      left: vw(235px);
      bottom: vw(191px);
    }
    
    &-16 {
      left: vw(261px);
      bottom: vw(114px);
    }

    &-17 {
      left: vw(834px);
      bottom: vw(233px);
    }
    
    &-18 {
      left: vw(870px);
      bottom: vw(216px);
    }
    
    &-19 {
      right: vw(300px);
      bottom: vw(200px);
    }
    
    &-20 {
      right: vw(533px);
      bottom: vw(113px);
    }
    
    &-21 {
      right: vw(400px);
      bottom: vw(120px);
    }
    
    &-22 {
      right: vw(310px);
      bottom: vw(98px);
    }

    &-23 {
      right: vw(412px);
      bottom: 0;
    }

  }
</style>