<template>
    <div class="container" id="fact_1" data-section="FactsFirstScreen">
        <div class="section__wrapper">
            <section class="content-move">
                <img src="../../assets/images/facts-character-lg.png" alt="Вітроля" class="facts-first__character" ref="character"
                  @mouseover="overHandler"
                  @mouseout="outHandler"
                  @mousedown.prevent="startScratchingHandler"
                  @mousemove="throttledScratchHandler"
                  @mouseup="endScratchingHandler"
                  @touchstart.prevent="startScratchingHandler"
                  @touchmove="throttledScratchHandler"
                  @touchend="endScratchingHandler">
                <span class="facts__scratch">
                    Почешіть мене!
                </span>
                <div class="facts__content facts-first__content">
                    <div class="facts__content-top">
                        <h6>
                            <span class="facts__content-supheading bold">
                                Поки вирішуєте як бути із цією пропозицією, тримайте
                            </span>
                            <br>
                            <span class="facts__content-subheading bold">
                                5 фактів про мене. 
                            </span>
                        </h6>
                        <div class="extra-bold--accent facts__title">
                            Поїхали!
                        </div>
                    </div>
                    <div class="card facts__card facts-first__card">
                        <div class="facts__card-step">
                            1.
                        </div>
                        <p class="facts__card-text">
                            Я сестра звичайного герпесу! На відміну від брата-зануди я не обмежуюсь губами, а викликаю сверблячу висипку по всьому тілу<sup>1</sup>. Укуси комариків і поруч не стояли!
                        </p>
                    </div>
                </div>
            </section>
            <transition-group name="fade">
              <div v-if="scratchCount < scratchStep * 3" key="base-group">
                <div class="hidden-group">
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
                </div>
                <inline-svg class="pimple pimple-1 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
                <inline-svg class="pimple pimple-2 pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
              </div>
            </transition-group>
            <transition-group name="fade">
              <div v-if="scratchCount >= scratchStep * 1 && scratchCount < scratchStep * 3" key="scratch__group-1">
              <div class="hidden-group">
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
                </div>
                <inline-svg class="itch__pimple itch__pimple-1 pimple-large" :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-2 pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-3 pimple-regular" :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-4 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-5 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>  
              </div>
            </transition-group>
            <transition-group name="fade">
              <div v-if="scratchCount >= scratchStep * 2 && scratchCount < scratchStep * 4" key="scratch__group-2">
                <div class="hidden-group">
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
                </div>
                <inline-svg class="itch__pimple itch__pimple-6 pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-7 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-8 pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
              </div>
            </transition-group>
            <transition-group name="fade">
              <div v-if="scratchCount >= scratchStep * 3 && scratchCount < scratchStep * 5" key="scratch__group-3">
                <div class="hidden-group">
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
                </div>
                <inline-svg class="itch__pimple itch__pimple-9 pimple-regular" :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-10 pimple-regular" :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-11 pimple-large" :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
              </div>
            </transition-group>
            <transition-group name="fade">
              <div v-if="scratchCount >= scratchStep * 4 && scratchCount < scratchStep * 6" key="scratch__group-4">
                <div class="hidden-group">
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
                </div>
                <inline-svg class="itch__pimple itch__pimple-12 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-13 pimple-large" :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-14 pimple-regular" :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-15 pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-16 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
              </div>
            </transition-group>
            <transition-group name="fade">
              <div v-if="scratchCount >= scratchStep * 5 && scratchCount < scratchStep * 7" key="scratch__group-5">
                <div class="hidden-group">
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
                  <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
                </div>
                <inline-svg class="itch__pimple itch__pimple-17 pimple-extra-regular" :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-18 pimple-extra-middle" :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-19 pimple-extra-regular" :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-20 pimple-extra-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-21 pimple-extra-middle" :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-22 pimple-extra-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__pimple-23 pimple-extra-middle" :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
              </div>
            </transition-group>
            <transition-group name="fade">
              <div v-if="scratchCount >= scratchStep * 3" key="scratch__group-6">
                <inline-svg class="itch__pimple itch__scar-1" :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-2" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-3" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-4" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-5" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-6" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>  
              </div>
            </transition-group>
            <transition-group name="fade">
              <div v-if="scratchCount >= scratchStep * 4" key="scratch__group-7">
                <inline-svg class="itch__pimple itch__scar-7" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-8" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-9" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
              </div>
            </transition-group>
            <transition-group name="fade">
              <div v-if="scratchCount >= scratchStep * 5" key="scratch__group-8">
                <inline-svg class="itch__pimple itch__scar-10" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-11" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-12" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
              </div>
            </transition-group>
            <transition-group name="fade">
              <div v-if="scratchCount >= scratchStep * 6" key="scratch__group-9">
                <inline-svg class="itch__pimple itch__scar-13" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-14" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-15" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-16" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-17" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
              </div>
            </transition-group>
            <transition-group name="fade">
              <div v-if="scratchCount >= scratchStep * 7" key="scratch__group-10">
                <inline-svg class="itch__pimple itch__scar-18" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-19" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-20" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-21" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-22" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-23" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
                <inline-svg class="itch__pimple itch__scar-24" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
              </div>
            </transition-group>
            <ScrollToBottom @go-next-section="$emit('go-next-section', 'FactsSecondScreen')" />
        </div>
    </div>
</template>

<script>
import scrollToMixin from '../../mixins/scrollToMixin';
import {throttle} from '../../utils/helpers';

    export default {
        name: 'FactsFirstScreen',
        mixins: [scrollToMixin],
        data() {
          return {
            scratchCount: 0,
            lastPositions: {},
            lastDirection: null,
            startedScratching: false,
            scratchStep: 2
          }
        },
        methods: {
          overHandler() {
            document.querySelector('.facts__scratch').classList.add('hovered');
          },

          outHandler() {
            document.querySelector('.facts__scratch').classList.remove('hovered');
          },

          startScratchingHandler(e) {
            // eslint-disable-next-line no-undef
              gtag('event', 'click', { 'event_category': 'main_btn_scratch'}) 
            if(e.type == 'touchstart') {
              document.querySelector('.facts__scratch').classList.add('hovered');
            }
            this.startedScratching = true;
            this.$refs.character.classList.add('is-scratching');
          },

          scratchHandler(e) {
            if(this.startedScratching) {
              
              if(e.type == 'touchmove'){
                const touch = e.touches[0];
                if (typeof(this.lastPositions.x) != 'undefined') {
                  let deltaX = this.lastPositions.x - touch.pageX,
                      deltaY = this.lastPositions.y - touch.pageY;

                    if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY > 0 && this.lastDirection !== 'top') {
                      this.lastDirection = 'top';
                      ++this.scratchCount;
                    } else if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY < 0 && this.lastDirection !== 'bottom') {
                      this.lastDirection = 'bottom';
                      ++this.scratchCount;
                      if(this.scratchCount > this.scratchStep * 7) this.scratchCount = 0;
                    }
                }

                this.lastPositions = {
                    x: touch.pageX,
                    y: touch.pageY
                };
              } else if (e.type == 'mousemove') {
                if (typeof(this.lastPositions.x) != 'undefined') {
                  let deltaX = this.lastPositions.x - event.clientX,
                      deltaY = this.lastPositions.y - event.clientY;

                    if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY > 0 && this.lastDirection !== 'top') {
                      this.lastDirection = 'top';
                      ++this.scratchCount;
                    } else if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY < 0 && this.lastDirection !== 'bottom') {
                      this.lastDirection = 'bottom';
                      ++this.scratchCount;
                      if(this.scratchCount > this.scratchStep * 7) this.scratchCount = 0;
                    }
                }

                this.lastPositions = {
                    x: e.clientX,
                    y: e.clientY
                };
              }
            }
          },

          endScratchingHandler(e) {
            if(e.type == 'touchend') {
              document.querySelector('.facts__scratch').classList.remove('hovered');
            }
            this.startedScratching = false;
            this.$refs.character.classList.remove('is-scratching');
          }
        },

        computed: {
          throttledScratchHandler() {
            return throttle(this.scratchHandler, 100)
          },
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';
  ::v-deep .pimple {
    &-1 {
      left: vw(960px);
      top: vw(268px);
    }
    
    &-2 {
      left: vw(990px);
      top: vw(197px);
    }


    @media screen and (max-width: $mobile) {
      &-1 {
        left: vwm(15px);
        top: vwm(29px);
      }
      
      &-2 {
        left: vwm(38px);
        top: vwm(11px);
      }
    }
  }

  ::v-deep .itch {
    &__pimple {
      position: absolute;
      &-1 {
        right: vw(450px);
        top: vw(50px);
      }

      &-2 {
        right: vw(330px);
        top: vw(117px);
      }

       &-3 {
        right: vw(560px);
        top: vw(180px);
      }

      &-4 {
        right: vw(692px);
        top: vw(235px);
      }

      &-5 {
        right: vw(469px);
        top: vw(250px);
      }

      &-6 {
        left: vw(60px);
        top: vw(50px);
      }

      &-7 {
        left: vw(260px);
        top: vw(68px);
      }

      &-8 {
        left: vw(305px);
        top: vw(115px);
      }

      &-9 {
        width: vw(82px);
        height: vw(82px);
        left: vw(150px);
        bottom: vw(230px);
      }

      &-10 {
        left: vw(275px);
        bottom: vw(140px);
      }

      &-11 {
        left: vw(140px);
        bottom: vw(90px);
      }

      &-12 {
        right: vw(191px);
        bottom: vw(350px);
      }

      &-13 {
        right: vw(290px);
        bottom: vw(260px);
      }

      &-14 {
        right: vw(190px);
        bottom: vw(207px);
      }

      &-15 {
        right: vw(40px);
        bottom: vw(200px);
      }

      &-16 {
        right: vw(183px);
        bottom: vw(132px);
      }

      &-17 {
        left: vw(580px);
        top: vw(270px);
      }

      &-18 {
        left: vw(940px);
        top: vw(460px);
      }

      &-19 {
        left: vw(740px);
        top: vw(150px);
      }

      &-20 {
        left: vw(891px);
        bottom: vw(350px);
      }

      &-21 {
        left: vw(790px);
        bottom: vw(260px);
      }

      &-22 {
        left: vw(690px);
        bottom: vw(307px);
      }

      &-23 {
        left: vw(580px);
        top: vw(500px);
      }
    }

    &__scar {
      &-1 {
        width: vw(137px);
        height: vw(138px);
        right: vw(440px);
        top: vw(60px);
      }

      &-2 {
        width: vw(96.5px);
        height: vw(98px);
        right: vw(345px);
        top: vw(130px);
      }

       &-3 {
        width: vw(93.5px);
        height: vw(95.4px);
        right: vw(570px);
        top: vw(190px);
      }

      &-4 {
        width: vw(73.5px);
        height: vw(75px);
        right: vw(685px);
        top: vw(230px);
      }

      &-5 {
        width: vw(73.5px);
        height: vw(75px);
        right: vw(460px);
        top: vw(245px);
      }

      &-6 {
        width: vw(133.9px);
        height: vw(134.5px);
        left: vw(960px);
        top: vw(200px);
      }

      &-7 {
        width: vw(79.4px);
        height: vw(81px);
        left: vw(88px);
        top: vw(76px);
      }

      &-8 {
        width: vw(46.3px);
        height: vw(47px);
        left: vw(262px);
        top: vw(69px);
      }

      &-9 {
        width: vw(99px);
        height: vw(101px);
        left: vw(330px);
        top: vw(135px);
      }

      &-10 {
        width: vw(35px);
        height: vw(35px);
        left: vw(175px);
        bottom: vw(255px);
      }

      &-11 {
        width: vw(67.6px);
        height: vw(69px);
        left: vw(303px);
        bottom: vw(169px);
      }

      &-12 {
        width: vw(99px);
        height: vw(101px);
        left: vw(169px);
        bottom: vw(111px);
      }

      &-13 {
        width: vw(51.9px);
        height: vw(53px);
        right: vw(185px);
        bottom: vw(345px);
      }

      &-14 {
        width: vw(73.7px);
        height: vw(75px);
        right: vw(325px);
        bottom: vw(296px);
      }

      &-15 {
        width: vw(73.7px);
        height: vw(75px);
        right: vw(214px);
        bottom: vw(226px);
      }

      &-16 {
        width: vw(131.9px);
        height: vw(126.5px);
        right: vw(59px);
        bottom: vw(202px);
      }

      &-17 {
        width: vw(52px);
        height: vw(53px);
        right: vw(179px);
        bottom: vw(128px);
      }

      &-18 {
        width: vw(46px);
        height: vw(47px);
        left: vw(600px);
        top: vw(290px);
      }

      &-19 {
        width: vw(73.5px);
        height: vw(75px);
        left: vw(950px);
        top: vw(470px);
      }

      &-20 {
        width: vw(52px);
        height: vw(53px);
        left: vw(760px);
        top: vw(170px);
      }

      &-21 {
        width: vw(30px);
        height: vw(31px);
        left: vw(896px);
        bottom: vw(350px);
      }

      &-22 {
        width: vw(52px);
        height: vw(53px);
        left: vw(811px);
        bottom: vw(280px);
      }

      &-23 {
        width: vw(52px);
        height: vw(53px);
        left: vw(690px);
        bottom: vw(300px);
      }

      &-24 {
        width: vw(52px);
        height: vw(53px);
        left: vw(600px);
        top: vw(520px);
      }

    }

    @media screen and (max-width: $mobile) {
      &__pimple {
        &-1 {
          right: 0;
          top: vwm(60px);
        }

        &-2 {
          right: vwm(10px);
          top: vwm(127px);
        }

        &-3 {
          right: vwm(110px);
          top: vwm(20px);
        }

        &-4 {
          right: vwm(80px);
          top: vwm(115px);
        }

        &-5 {
          right: vwm(80px);
          top: vwm(155px);
        }

        &-6 {
          left: 0;
          top: vwm(180px);
        }

        &-7 {
          left: vwm(120px);
          top: vwm(138px);
        }

        &-8 {
          left: 0;
          top: vwm(115px);
        }

        &-9 {
          width: vwm(44px);
          height: vwm(44px);
          left: 0;
          bottom: vwm(90px);
        }

        &-10 {
          left: vwm(55px);
          bottom: vwm(60px);
        }

        &-11 {
          left: 0;
          bottom: 0;
        }

        &-12 {
          right: 0;
          bottom: 0;
        }

        &-13 {
          right: vwm(90px);
          bottom: vwm(10px);
        }

        &-14 {
          right: 0;
          bottom: vwm(110px);
        }

        &-15 {
          right: vwm(40px);
          bottom: vwm(200px);
        }

        &-16 {
          right: vwm(43px);
          bottom: vwm(62px);
        }

        &-17 {
          left: vwm(20px);
          top: vwm(360px);
        }

        &-18 {
          left: vwm(160px);
          top: vwm(350px);
        }

        &-19 {
          left: vwm(80px);
          top: auto;
          bottom: vwm(120px);
        }

        &-20 {
          left: vwm(60px);
          bottom: vwm(170px);
        }

        &-21 {
          left: auto;
          right: vwm(80px);
          bottom: vwm(160px);
        }

        &-22 {
          display: none;
        }

        &-23 {
          display: none;
        }
      }

      &__scar {
        &-1 {
          width: vwm(60px);
          height: vwm(60px);
          right: vwm(10px);
          top: vwm(80px);
        }

        &-2 {
          width: vwm(30px);
          height: vwm(32px);
          right: vwm(130px);
          top: vwm(37px);
        }

        &-3 {
          width: vwm(25px);
          height: vwm(25px);
          right: vwm(20px);
          top: vwm(140px);
        }

        &-4 {
         width: vwm(25px);
          height: vwm(25px);
          right: vwm(84px);
          top: vwm(159px);
        }

        &-5 {
          width: vwm(25px);
          height: vwm(25px);
          right: vwm(80px);
          top: vwm(119px);
        }

        &-6 {
          width: vwm(80px);
          height: vwm(82px);
          left: 0;
          top: 0;
        }

        &-7 {
          width: vwm(25px);
          height: vwm(25px);
          left: vwm(15px);
          top: vwm(126px);
        }

        &-8 {
          width: vwm(25px);
          height: vwm(25px);
          left: vwm(15px);
          top: vwm(190px);
        }

        &-9 {
          width: vwm(25px);
          height: vwm(25px);
          left: vwm(125px);
          top: vwm(140px);
        }

        &-10 {
          width: vwm(35px);
          height: vwm(35px);
          left: vwm(5px);
          bottom: vwm(94px);
        }

        &-11 {
          width: vwm(37.6px);
          height: vwm(39px);
          left: vwm(70px);
          bottom: vwm(70px);
        }

        &-12 {
          width: vwm(68px);
          height: vwm(71px);
          left: vwm(10px);
          bottom: vwm(10px);
        }

        &-13 {
          width: vwm(31.9px);
          height: vwm(33px);
          right: 0;
          bottom: 0;
        }

        &-14 {
          width: vwm(53.7px);
          height: vwm(55px);
          right: vwm(106px);
          bottom: vwm(30px);
        }

        &-15 {
          width: vwm(33.7px);
          height: vwm(35px);
          right: vwm(13px);
          bottom: vwm(123px);
        }

        &-16 {
          width: vwm(41.9px);
          height: vwm(44.5px);
          right: vwm(50px);
          bottom: vwm(200px);
        }

        &-17 {
          width: vwm(32px);
          height: vwm(33px);
          right: vwm(43px);
          bottom: vwm(62px);
        }

        &-18 {
          width: vwm(26px);
          height: vwm(27px);
          left: vwm(35px);
          top: vwm(370px);
        }

        &-19 {
          width: vwm(30.5px);
          height: vwm(30px);
          left: vwm(165px);
          top: vwm(358px);
        }

        &-20 {
          width: vwm(22px);
          height: vwm(23px);
          left: vwm(96px);
          top: auto;
          bottom: vwm(135px);
        }

        &-21 {
          width: vwm(20px);
          height: vwm(21px);
          left: vwm(60px);
          bottom: vwm(167px);
        }

        &-22 {
          width: vwm(22px);
          height: vwm(23px);
          left: auto;
          right: vwm(90px);
          bottom: vwm(170px);
        }

        &-23 {
          display: none;
        }

        &-24 {
          display: none;
        }

      }
    }
  }
</style>