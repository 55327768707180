<template functional>
    <div class="section__wrapper">
        <div class="hide-mobile">

            <inline-svg class="pimple pimple-1 pimple-large" :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
            <inline-svg class="pimple pimple-2 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-3 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-4 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

            <inline-svg class="pimple pimple-5 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-6 pimple-regular" :src="require('../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
            <inline-svg class="pimple pimple-7 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-8 pimple-large" :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>

            <inline-svg class="pimple pimple-9 scar-regular" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>

            <inline-svg class="pimple pimple-10 scar-large" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-11 scar-small" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>

        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
      name: "LayoutFirst"
    }
</script>

<style lang="scss" scoped>
@import '../scss/mixin';
  ::v-deep .pimple {
    &-1 {
      left: vw(66px);
      top: vw(248px);
    }
    
    &-2 {
      left: vw(23px);
      top: vw(375px);
    }
    
    &-3 {
      left: vw(60px);
      top: vw(311px);
    }
    
    &-4 {
      left: vw(61px);
      top: vw(378px);
    }
    
    &-5 {
      right: vw(90px);
      top: vw(281px);
    } 
    
    &-6 {
      right: vw(170px);
      top: vw(78px);
    }
    
    &-7 {
      right: vw(125px);
      top: vw(170px);
    }

    &-8 {
      right: vw(153px);
      top: vw(253px);
    }
    
    &-9 {
      right: vw(128px);
      top: vw(357px);
    }
    
    &-10 {
      left: vw(1px);
      top: vw(135px);
    }
    
    &-11 {
      left: vw(25px);
      top: vw(447px);
    }
  }
</style>