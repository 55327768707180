<template>
  <div class="container" id="main" data-section="Hero">
      <div class="hidden-group" v-if="!isMobile()">
        <inline-svg :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        <inline-svg :src="require('../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
        <inline-svg :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg> 
        <inline-svg :src="require('../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
        <inline-svg :src="require('../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
      </div>
    <LayoutFirst>
      <section class="content-move">
        <div class="section-hero__content">
            <h1 class="extra-bold--accent section-hero__title">
                <nobr>Привіт, батьки,</nobr><br>
                <span>я Вітроля —</span> 
            </h1>
            <span class="bold section-hero__subtitle">офіційне обличчя бренду вітрянка!</span>
            <p class="section-hero__text">
                Запрошую на незабутній 2-тижневий марафон недоспаних ночей, свербіння та плачу. Щоб взяти участь, нічого робити не потрібно – вітрянка сама знайде вашу родину. 
            </p>
        </div>
        <div class="gift section-hero__gift">
            <div class="gift__content">
                <h6 class="gift__title">
                    Батьки, цей <br> подарунок – для вас!
                </h6>
                <div class="gift__subtitle">
                    Тисніть, щоб отримати подарунок!
                </div>
            </div>
            <div class="gift__img" @click="giftPopupOpen" onClick="gtag('event', 'click', { 'event_category': 'main_btn_suprise'}) " :class="{'opened': giftOpened}">
              <img src="../assets/images/gift.png" alt="подарунок">
              <div class="gift__hand">
                <img src="../assets/images/gift-hand.svg" alt="натисни щоб отримати подарунок">
              </div>
            </div>
        </div>
        <img src="../assets/images/home-character.png" alt="Вітроля" class="section-hero__character">
      </section>
      <div class="show-mobile">
        <inline-svg class="pimple pimple-1-sm pimple-large" :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg class="pimple pimple-2-sm pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg class="pimple pimple-3-sm pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg class="pimple pimple-4-sm scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>
        <inline-svg class="pimple pimple-5-sm scar-small-mobile" :src="require('../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg>
      </div>
      <ScrollToBottom @go-next-section="$emit('go-next-section', 'FactsSixthScreen')" />
      <Overlay :show="giftClicked"></Overlay>
        <transition-group
            v-bind:css="false"
            tag="div"
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
                v-on:leave="leave">
            <Popup v-for="popup in popupsToAnimate" :key="popup.id"
                @popup-close="giftPopupClose" 
                :isActive="popup.isActive"
                :id="popup.id"
                :data-index="popup.id"
                :data-from="popup.from"
                :data-to="popup.to"
                >
                <template #top>
                    {{ popup.headerText }}
                </template>
                <template #main>
                    {{ popup.mainText }}
                </template>
            </Popup> 
        </transition-group>
    </LayoutFirst>
    </div>
</template>

<script>
import scrollToMixin from '../mixins/scrollToMixin';
import Popup from '../components/Popup';
import Overlay from '../components/Overlay';
import LayoutFirst from '../components/LayoutFirst';

export default {
  name: 'Hero',
  mixins: [scrollToMixin],
  components: {
    Popup,
    Overlay,
    LayoutFirst
  },
  data() {
    return {
      giftClicked: false,
      giftOpened: false,
      popups: [
          {
              id: 1,
              headerText: "Це не жарт!",
              mainText: `Свербіж, що "змусить танцювати"`,
              isActive: false,
              from: 'translateX(10vw)',
              to: 'translateX(0)'
          },
          {
              id: 2,
              headerText: "Це не жарт!",
              mainText: `Пухирі для тих, хто любить їх здирати`,
              isActive: false,
              from: 'translateX(-10vw)',
              to: 'translateX(0)'
          },
          {
              id: 3,
              headerText: "Це не жарт!",
              mainText: `Мішки під очима від безсонних ночей`,
              isActive: false,
              from: 'translateX(10vw)',
              to: 'translateX(0)'
          },
          {
              id: 4,
              headerText: "Це не жарт!",
              mainText: `Модні шрами на обличчі`,
              isActive: true,
              from: 'translateY(10vw)',
              to: 'translateY(0)'
          }
      ]
    }
  },
  methods: {
    giftPopupOpen() {
        this.giftClicked = true;
        this.giftOpened = true;
    },

    giftPopupClose() {
        this.giftClicked = false;
        this.giftOpened = false;
        // eslint-disable-next-line no-undef
        gtag('event', 'click', { 'event_category': 'main_btn_suprise_pop_up'}) 
    },

    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = el.dataset.from;
    },

    enter(el) {
      const delay = el.dataset.index * 400;
      el.style.transitionDuration = '500ms';
      setTimeout(function () {
        el.style.opacity = 1;
        el.style.transform = el.dataset.to;
      }, delay)
    },

    leave(el) {
      const delay = el.dataset.index * 150;
      el.style.transitionDuration = '500ms';
      setTimeout(function () {
        el.style.opacity = 0;
        el.style.transform = el.dataset.from;
      }, delay)
    },

  },
  computed: {
    popupsToAnimate() {
        return this.giftClicked ? this.popups : [];
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/vars';
@import '../scss/mixin';
  .pimple {
    @media screen and (max-width: $mobile) {

      &-1-sm {
        left: vwm(155px);
        top: vwm(5px);
      }
      
      &-2-sm {
        left: vwm(140px);
        top: vwm(20px);
      }
      
      &-3-sm {
        left: vwm(100px);
        top: auto;
        top: vwm(45px);
      }

      &-4-sm {
        left: vwm(22px);
        top: vwm(183.8px); 
      }

      &-5-sm {
        left: vwm(8px);
        top: vwm(175px); 
      }
    }
  }

  .gift-popup {
    &--1 {
      left: 50vw;
      top: 29%;
    }

    &--2 {
      left: 22.86vw;
      top: 35%;
    }
    
    &--3 {
      left: 49.7vw;
      top: 53%;
    }
    
    &--4{
      left: 39.8vw;
      top: 41%;
    }

    @media screen and (min-width: $minMobile) and (max-height: $maxHeightD) {
      &--1 {
        left: 50vw;
        top: 21%;
      }

      &--2 {
        left: 22.86vw;
        top: 28.5%;
      }
      
      &--3 {
        left: 49.7vw;
        top: 54%;
      }
      
      &--4{
        left: 39.8vw;
        top: 37%;
      }
    }

    @media screen and (max-width: $mobile) {
      &--1 {
        left: 6.4vw;
        top: 47.77vw;
      }

      &--2 {
        left: 25vw;
        top: vwm(449px);
      }
      
      &--3 {
        left: 28.88vw;
        top: 16.4vw;
      }
      
      &--4{
        left: 15.8vw;
        top: 74.4vw;
      }
    }
  }
</style>
