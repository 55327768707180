<template>
    <div class="container" id="historyLast">
        <section>
            <div class="history__goodbye-text">
                <span class="extra-bold--accent history__goodbye-title">
                    До зустрічі, друзі!
                </span>
                <span class="bold history__goodbye-subheading">
                    Я прийду тоді, коли ви не чекатимете.
                </span>
            </div>
            <img src="../../assets/images/history-2-character.png" alt="Вітроля" class="history__goodbye-character">
        </section>
        <div class="hidden-group" v-if="!isMobile()">
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
          <inline-svg :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
          <inline-svg :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
          <inline-svg :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
          <inline-svg :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
        </div>
        <div class="hide-mobile">
          <inline-svg class="pimple pimple-1" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
          <inline-svg class="pimple pimple-2 pimple-regular" :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
          <inline-svg class="pimple pimple-3" :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg>
          <inline-svg class="pimple pimple-4" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
          <inline-svg class="pimple pimple-5" :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-6 scar-small" :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-7 scar-small" :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-8" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
          <inline-svg class="pimple pimple-9" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
          <inline-svg class="pimple pimple-10" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
          <inline-svg class="pimple pimple-11 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-12 pimple-large" :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg> 
        </div>
        <div class="show-mobile">
          <inline-svg class="pimple pimple-1-sm scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>
          <inline-svg class="pimple pimple-2-sm scar-small-mobile" :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-3-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-4-sm pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-5-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>  
        </div>
    </div>
</template>

<script>
import {isMobile} from '../../utils/helpers';
    export default {
        name: "HistorySecondScreen",
        methods: {
          isMobile: isMobile
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';
.container {
  position: relative !important;
}
  .pimple {
    &-1 {
      width: vw(79px);
      height: vw(81px);
      right: vw(501px);
      top: 0;
    }
    
    &-2 {
      left: vw(9px);
      top: vw(80px);
    }
    
    &-3 {
      width: vw(122px);
      height: vw(123px);
      left: vw(467px);
      top: vw(106px);
    }
    
    &-4 {
      width: vw(77px);
      height: vw(79px);
      right: vw(600px);
      top: vw(79px);
    }
    
    &-5 {
      width: vw(41px);
      height: vw(42px);
      right: vw(539px);
      top: vw(98px);
    }
    
    &-6 {
      left: vw(116px);
      top: vw(225px);
    }
    
    &-7 {
      left: vw(441px);
      top: vw(209px);
    }
    
    &-8 {
      width: vw(83px);
      height: vw(85px);
      left: vw(495px);
      top: vw(223px);
    } 
    
    &-9 {
      width: vw(103px);
      height: vw(105px);
      right: vw(469px);
      top: vw(140px);
    }
    
    &-10 {
      width: vw(87px);
      height: vw(89px);
      right: vw(77px);
      top: vw(268px);
    }
    
    &-11 {
      left: vw(450px);
      bottom: vw(120px);
    }
    
    &-12 {
      left: vw(150px);
      bottom: 0;
    }

  @media screen and (max-width: $mobile) {
      &-1-sm {
        right: vwm(18px);
        top: vwm(105px);
      }
      
      &-2-sm {
        right: vwm(39px);
        top: vwm(159px);
      }
      
      &-3-sm {
        right: vwm(85px);
        top: vwm(207px);
      }
      
      &-4-sm {
        right: vwm(94px);
        top: vwm(231px);
      }
      
      &-5-sm {
        right: vwm(60px);
        top: vwm(235px);
      }
    }
  } 
    section {
        height: 40vw;
        @media screen and (max-width: $mobile) {
            height: auto;
            padding-top: vwm(50px);
            z-index: 10;
        }
    }

    .container {
        height: auto;
    }
</style>