<template>
    <div class="container" id="faq" data-section="FaqFirstScreen">
    <div class="hidden-group" v-if="!isMobile()">
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
      </div>
      <LayoutThird>
        <section class="content-move">
            <div class="faq__wrapper">
                <h5 class="bold supheading">Можливо, у вас лишились запитання?</h5>
                <h6 class="extra-bold--accent title">Обирайте, не соромтеся!</h6>
                <div class="faq__cards-container">
                    <article class="card faq__card">
                        <h6 class="faq__card-question">
                            Наскільки ефективна вакцинація проти вітряної віспи?<sup>1</sup>
                        </h6>
                        <p class="faq__card-answer">
                            Через 10 років після одноразового щеплення імунітет зберігався у 94,4% людей та у 98,8% серед тих, хто вакцинувався двічі.
                        </p>
                    </article>
                    <article class="card faq__card">
                        <h6 class="faq__card-question">
                            У яких груп найчастіше розвиваються ускладнення?<sup>1</sup>
                        </h6>
                        <p class="faq__card-answer">
                            <ul class="faq__list">
                                <li class="faq__list-item">
                                    Новонароджені і немовлята
                                </li>
                                <li class="faq__list-item">
                                    Вагітні
                                </li>
                                <li class="faq__list-item">
                                    Дорослі люди    
                                </li>
                                <li class="faq__list-item">
                                    Особи з порушенням функції імунної системи
                                </li>
                            </ul>
                        </p>
                    </article>
                    <article class="card faq__card">
                        <h6 class="faq__card-question">
                            Чи безпечна вакцинація проти вітряної віспи?<sup>1</sup>
                        </h6>
                        <p class="faq__card-answer">
                            Так. Зазвичай  щеплення має прийнятну переносимість. Достатньо рідко можуть спостерігатись: почервоніння 
                            у місці введення вакцини, легка хворобливість, висипання і підвищення температури тіла.
                        </p>
                    </article>
                    <article class="card faq__card">
                        <h6 class="faq__card-question">
                            Чи існує лікування вітрянки, що впливає на вірус, а не усуває прояви?<sup>1</sup>
                        </h6>
                        <p class="faq__card-answer">
                            Лікування призначає лікар, при тяжкому перебігу у випадку високого ризику ускладнень можуть використовуватись специфічні препарати.
                        </p>
                    </article>
                </div>
            </div>
        </section>
        <div class="show-mobile">
            <inline-svg class="pimple pimple-1-sm scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>
            <inline-svg class="pimple pimple-2-sm scar-small-mobile" :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg>
            <inline-svg class="pimple pimple-3-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-4-sm pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
            <inline-svg class="pimple pimple-5-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        </div>
        <ScrollToBottom @go-next-section="$emit('go-next-section', 'FaqSecondScreen')" />
      </LayoutThird>
    </div>
</template>

<script>
import scrollToMixin from '../../mixins/scrollToMixin';
import LayoutThird from '../../components/LayoutThird';

    export default {
        name: "FaqFirstScreen",
        components: {
          LayoutThird
        },
        mixins: [scrollToMixin]
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';
  .pimple {
    @media screen and (max-width: $mobile) {
      &-1-sm {
        right: vwm(18px);
        top: vwm(105px);
      }
      
      &-2-sm {
        right: vwm(39px);
        top: vwm(159px);
      }
      
      &-3-sm {
        right: vwm(90px);
        top: vwm(155px);
      }
      
      &-4-sm {
        right: vwm(99px);
        top: vwm(180px);
      }
      
      &-5-sm {
        right: vwm(65px);
        top: vwm(185px);
      }
    }

  }  
    @media screen and (max-width: $mobile) {
       .container {
           height: auto;
       }

       section {
           height: auto;
       }
    }
</style>