<template>
  <div id="app" class="wrapper">
    <Menu
      :menuOpened.sync="menuOpened"
      @toggle-menu="menuOpened = !menuOpened"
      @navigate-to-screen="navigateToScreen"
      :navigating="navigating"
      @change-direction="changeDirection"
    />
    <transition
      name="page"
      mode="in-out"
      v-if="!isMobile()"
      v-on:beforeEnter="beforeEnter"
    >
      <keep-alive>
        <component
          :is="getScreen.name"
          @go-next-section="goNextSection"
          :navigating="navigating"
        />
      </keep-alive>
    </transition>
    <div v-if="isMobile()">
      <Hero class="section" />
      <!-- <FirstSeptFirstScreen class="section" />
      <FirstSeptSecondScreen class="section" />
      <FirstSeptThirdScreen class="section" /> -->
      <FactsSixthScreen class="section" />
      <FactsFirstScreen class="section" />
      <FactsSecondScreen class="section" />
      <FactsThirdScreen class="section" />
      <FactsFourthScreen class="section" />
      <FactsFifthScreen class="section" />
      <Vaccination class="section" />
      <TimeFirstScreen class="section" />
      <TimeSecondScreen class="section" />
      <RiskGroupsFirstScreen class="section" />
      <RiskGroupsSecondScreen class="section" />
      <FaqFirstScreen class="section" />
      <FaqSecondScreen class="section" />
      <Footer class="section" />
    </div>
    <audio src="./assets/sounds/haha.mp3" loop id="audio"></audio>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import Menu from "./components/Menu";
import Hero from "./views/Hero";
import FirstSeptFirstScreen from "./views/first-sept/FirstSeptFirstScreen";
import FirstSeptSecondScreen from "./views/first-sept/FirstSeptSecondScreen";
import FirstSeptThirdScreen from "./views/first-sept/FirstSeptThirdScreen";
import FactsFirstScreen from "./views/facts/FactsFirstScreen";
import FactsSecondScreen from "./views/facts/FactsSecondScreen";
import FactsThirdScreen from "./views/facts/FactsThirdScreen";
import FactsFourthScreen from "./views/facts/FactsFourthScreen";
import FactsFifthScreen from "./views/facts/FactsFifthScreen";
import FactsSixthScreen from "./views/facts/FactsSixthScreen";
import Vaccination from "./views/Vaccination";
import TimeFirstScreen from "./views/time/TimeFirstScreen";
import TimeSecondScreen from "./views/time/TimeSecondScreen";
import RiskGroupsFirstScreen from "./views/risk-groups/RiskGroupsFirstScreen";
import RiskGroupsSecondScreen from "./views/risk-groups/RiskGroupsSecondScreen";
import FaqFirstScreen from "./views/faq/FaqFirstScreen";
import FaqSecondScreen from "./views/faq/FaqSecondScreen";
import Footer from "./components/Footer";
import { isMobile, throttle } from "./utils/helpers";
import showOnScroll from "./utils/showOnScroll";

export default {
  name: "App",

  components: {
    Menu,
    Hero,
    // FirstSeptFirstScreen,
    // FirstSeptSecondScreen,
    // FirstSeptThirdScreen,
    FactsFirstScreen,
    FactsSecondScreen,
    FactsThirdScreen,
    FactsFourthScreen,
    FactsFifthScreen,
    FactsSixthScreen,
    Vaccination,
    TimeFirstScreen,
    TimeSecondScreen,
    RiskGroupsFirstScreen,
    RiskGroupsSecondScreen,
    FaqFirstScreen,
    FaqSecondScreen,
    Footer,
  },
  data() {
    return {
      menuOpened: false,
      mobile: false,
      menuTop: null,
      currentScreen: 0,
      bottomNav: true,
      navigating: false,
      screens: [
        {
          name: "Hero",
          screenIndex: 0,
          sectionId: "main",
          scrollable: false,
          hash: '#main'
        },
        // {
        //   name: "FirstSeptFirstScreen",
        //   screenIndex: 1,
        //   sectionId: "first-sept_1",
        //   scrollable: false,
        //   hash: '#fs_1'
        // },
        // {
        //   name: "FirstSeptSecondScreen",
        //   screenIndex: 2,
        //   sectionId: "first-sept_1",
        //   scrollable: false,
        //   hash: '#fs_2'
        // },
        // {
        //   name: "FirstSeptThirdScreen",
        //   screenIndex: 3,
        //   sectionId: "first-sept_1",
        //   scrollable: false,
        //   hash: '#fs_3'
        // },
        {
          name: "FactsSixthScreen",
          screenIndex: 1,
          sectionId: "true_or_false",
          scrollable: false,
          hash: '#true_or_false'
        },
        {
          name: "FactsFirstScreen",
          screenIndex: 2,
          sectionId: "true_or_false",
          scrollable: false,
          hash: '#fact_1'
        },
        {
          name: "FactsSecondScreen",
          screenIndex: 3,
          sectionId: "true_or_false",
          scrollable: false,
          hash: '#fact_2'
        },
        {
          name: "FactsThirdScreen",
          screenIndex: 4,
          sectionId: "true_or_false",
          scrollable: false,
          hash: '#fact_3'
        },
        {
          name: "FactsFourthScreen",
          screenIndex: 5,
          sectionId: "true_or_false",
          scrollable: false,
          hash: '#fact_4'
        },
        {
          name: "FactsFifthScreen",
          screenIndex: 6,
          sectionId: "true_or_false",
          scrollable: false,
          hash: '#fact_5'
        },
        {
          name: "Vaccination",
          screenIndex: 7,
          sectionId: "percentage",
          scrollable: false,
          hash: '#percentage'
        },
        {
          name: "TimeFirstScreen",
          screenIndex: 8,
          sectionId: "72_hours",
          scrollable: false,
          hash: '#72_hours'
        },
        {
          name: "TimeSecondScreen",
          screenIndex: 9,
          sectionId: "72_hours",
          scrollable: false,
          hash: '#sm_share'
        },
        {
          name: "RiskGroupsFirstScreen",
          screenIndex: 10,
          sectionId: "group_of_risks",
          scrollable: false,
          hash: '#group_of_risks'
        },
        {
          name: "RiskGroupsSecondScreen",
          screenIndex: 11,
          sectionId: "group_of_risks",
          scrollable: false,
          hash: '#voice'
        },
        {
          name: "FaqFirstScreen",
          screenIndex: 12,
          sectionId: "faq",
          scrollable: false,
          hash: '#faq'
        },
        {
          name: "FaqSecondScreen",
          screenIndex: 13,
          sectionId: "faq",
          scrollable: false,
          hash: '#insta'
        },
        {
          name: "Footer",
          screenIndex: 14,
          sectionId: "history",
          scrollable: true,
          hash: '#history'
        },
      ],
    };
  },
  watch: {
    menuOpened(isOpened) {
      isOpened
        ? document.body.classList.add("menu-active")
        : document.body.classList.remove("menu-active");
    },
    currentScreen(screen){
      if(screen === 0){
        gtag("event", "onLoad", { event_category: "load_sec_main" });
      }
      // else if(screen === 1){
      //   gtag("event", "onLoad", { event_category: "load_sec_fs_1" });
      // }
      // else if(screen === 2){
      //   gtag("event", "onLoad", { event_category: "load_sec_fs_2" });
      // }
      // else if(screen === 3){
      //   gtag("event", "onLoad", { event_category: "load_sec_fs_3" });
      // }
      else if(screen === 1){
        gtag("event", "onLoad", { event_category: "load_true_or_false" });
      }
      else if(screen === 2){
        gtag("event", "onLoad", { event_category: "load_sec_fact_1" });
      }
      else if(screen === 3){
        gtag("event", "onLoad", { event_category: "load_sec_fact_2" });
      }
      else if(screen === 4){
        gtag("event", "onLoad", { event_category: "load_sec_fact_3" });
      }
      else if(screen === 5){
        gtag("event", "onLoad", { event_category: "load_sec_fact_4" });
      }
      else if(screen === 6){
        gtag("event", "onLoad", { event_category: "load_sec_fact_5" });
      }
      else if(screen === 7){
        gtag("event", "onLoad", { event_category: "load_sec_percentage" });
      }
      else if(screen === 8){
        gtag("event", "onLoad", { event_category: "load_sec_72_hours" });
      }
      else if(screen === 9){
        gtag("event", "onLoad", { event_category: "load_sec_sm_share" });
      }
      else if(screen === 10){
        gtag("event", "onLoad", { event_category: "load_sec_group_of_risks" });
      }
      else if(screen === 11){
        gtag("event", "onLoad", { event_category: "load_sec_voice" });
      }
      else if(screen === 12){
        gtag("event", "onLoad", { event_category: "load_sec_faq" });
      }
      else if(screen === 13){
        gtag("event", "onLoad", { event_category: "load_sec_insta" });
      }
      else if(screen === 14){
        gtag("event", "onLoad", { event_category: "load_sec_history" });
      }
     
    }
  },

  created() {
    if (!isMobile()) {
      this.setHashDesktop();
    } else {
      this.setHashMobile();
    }
  },

  mounted() {
    if(this.currentScreen === 0){
      gtag("event", "onLoad", { event_category: "load_sec_main" });
    }
    if (!isMobile()) {
      window.addEventListener("wheel", this.debouncedNavigateOnScroll, {passive: false});
      window.addEventListener("load", this.setFullpage);
      this.changeMenuOnHash();
    } else {
      // window.addEventListener("DOMContentLoaded", this.setCorrectHeightForMobile);
      // window.addEventListener("load", this.setCorrectHeightForMobile);
      // window.addEventListener("load", this.setCorrectHeightForMobile);
      window.addEventListener("load", () => showOnScroll(".pimple", "fade-in"));
      window.addEventListener("load", this.showSectionOnHash);
    }
    // window.addEventListener("resize", this.setCorrectHeightForMobile);

    // window.addEventListener(
    //   "orientationchange",
    //   this.setCorrectHeightForMobileOrientation
    // );
  },

  methods: {
    navigateToScreen(name) {
      if (
        !this.navigating &&
        !document.body.classList.contains("overlay-active")
      ) {
        this.navigating = true;
        document.body.style.overflow = "hidden";
        document.querySelector(".side-menu").classList.remove("absolute");

        const screen = this.screens.find((el) => el.name == name);
        document
          .querySelector(
            `[data-section="${this.screens[this.currentScreen].name}"]`
          )
          .classList.remove("fade-in-desk");

        if (this.bottomNav) {
          Array.from(
            document
              .querySelector(
                `[data-section="${this.screens[this.currentScreen].name}"]`
              )
              .querySelectorAll(".content-move")
          ).forEach((el) => {
            el.style.transform = "translateY(-150%)";
            setTimeout(() => {
              el.style.transform = "";
            }, 1250);
          });
        } else {
          Array.from(
            document
              .querySelector(
                `[data-section="${this.screens[this.currentScreen].name}"]`
              )
              .querySelectorAll(".content-move")
          ).forEach((el) => {
            el.style.transform = "translateY(150%)";
            setTimeout(() => {
              el.style.transform = "";
            }, 1250);
          });
        }

        const link = document.querySelector(`a[href="#${screen.sectionId}"]`);
        console.log(link);
        if (link) {
          if (!link.classList.contains("menu__link--active")) {
            document
              .querySelector(".menu__link--active")
              .classList.remove("menu__link--active");
            link.classList.add("menu__link--active");
          }
        }
        this.currentScreen = screen.screenIndex;
        window.history.replaceState({}, null, screen.hash);
        setTimeout(() => {
          document
            .querySelector(`[data-section="${screen.name}"]`)
            .classList.add("fade-in-desk");
          if (this.screens[this.currentScreen].scrollable) {
            document.body.style.overflow = "visible";
            document.querySelector(".side-menu").classList.add("absolute");
          }
        }, 1250);
        setTimeout(() => {
          this.navigating = false;
        }, 1400);
      }
    },

    beforeEnter(el) {
      if (this.bottomNav) {
        el.classList.remove("bottom");
      } else {
        el.classList.add("bottom");
      }
    },

    setCorrectHeightForMobile() {
      document.documentElement.style.setProperty(
        "--vh",
        window.innerHeight / 100 + "px"
      ); //mobile browser take some space for task bar. so its a fix for 100vh in mobile browsers
    },

    setCorrectHeightForMobileOrientation() {
      setTimeout(() => {
        this.setCorrectHeightForMobile();
      }, 50);
    },

    navigateOnScroll(e) {
      e.preventDefault();
      if (e.deltaY > 0) {
        this.bottomNav = true;
        if (this.currentScreen == this.screens.length - 1) return;
        this.navigateToScreen(this.screens[this.currentScreen + 1].name);
      } else if (e.deltaY < 0) {
        this.bottomNav = false;
        if (this.currentScreen == 0) return;
        if (!this.screens[this.currentScreen].scrollable) {
          this.navigateToScreen(
            this.screens[this.currentScreen - 1].name,
            false
          );
        } else {
          if (window.pageYOffset <= 0) {
            this.navigateToScreen(
              this.screens[this.currentScreen - 1].name,
              false
            );
          }
        }
      }
    },

    setFullpage() {
      document
        .querySelector(
          `[data-section="${this.screens[this.currentScreen].name}"]`
        )
        .classList.add("fade-in-desk");
      document.body.classList.add("fullpage");
    },

    goNextSection(name) {
      this.bottomNav = true;
      this.navigateToScreen(name);
    },

    changeDirection(val) {
      this.bottomNav = val;
    },

    setHashDesktop() {
      if (location) {
        const hash = location.hash;
        if (hash) {
          const screen = this.screens.find(el => el.hash === hash);
          if (screen) {
            this.currentScreen = screen.screenIndex;
          } else {
            this.currentScreen = 0;
            const hash = this.screens.find(el => el.screenIndex === 0).hash;
            window.history.replaceState({}, null, hash);
          }
        } else {
          const screenHash = this.screens.find(el => el.screenIndex === this.currentScreen).hash;
          window.history.replaceState({}, null, screenHash);
        }
      }
    },

    setHashMobile() {
      if (location) {
        const hash = location.hash;
        if (!hash) {
          window.history.replaceState({}, null, '#main');
        }
      }
    },

    changeMenuOnHash() {
      const screen = this.screens.find(el => el.screenIndex === this.currentScreen);
      document
        .querySelector(".menu__link--active")
        .classList.remove("menu__link--active");
      document
        .querySelector(`[href="#${screen.sectionId}"]`)
        .classList.add("menu__link--active");
    },

    showSectionOnHash() {
      if (location) {
        const hash = location.hash;
        if (hash) {
          document.getElementById(hash.slice(1)).scrollIntoView({behavior: 'smooth'});
        }
      }
    },

    isMobile: isMobile,
  },

  computed: {
    getScreen() {
      return this.screens[this.currentScreen];
    },

    debouncedNavigateOnScroll() {
      return throttle(this.navigateOnScroll, 1250);
    }
  },
};
</script>

<style lang="scss">
@import "scss/common";

@media screen and (max-width: 768px) {
  * {
    outline: none !important;
    cursor: none !important;
  }
}

.page-enter-active {
  overflow: hidden;
  transition: transform 1250ms cubic-bezier(0.99, 0.01, 0.44, 0.99);
}

.page-enter {
  transform: translateY(100%);
}

.page-enter.bottom {
  transform: translateY(-100%);
}

.page-leave-active {
  display: block;
}
</style>

