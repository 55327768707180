export default function(elClass, callback) {
    if(!elClass) return;
     const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0
    }

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if(typeof callback == 'function') {
                    callback();
                    observer.unobserve(entry.target);
                }
            }
        })
    }, options)

    const elements = document.querySelectorAll(elClass);
    elements.forEach(el => {
        observer.observe(el)
    });
}