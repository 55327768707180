<template>
    <div class="container" id="voice" data-section="RiskGroupsSecondScreen">
      <div class="hidden-group" v-if="!isMobile()">
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
      </div>
      <LayoutSecond>
        <section class="content-move">
            <div class="card risk-second__card">
                <p>
                    Круто, коли мами обговорюють вітрянку в інтернеті, поширюють між собою міфи та обирають сумнівні методи захисту.
                </p> 
                <p>
                    Коли я приходжу в гості до такої родини, завжди застаю їх <span class="marked extra-bold--accent">зненацька!</span>
                </p>
            </div>
            <div class="card risk-second__tip">
              <p class="text">
                Щоб <strong class="marked extra-bold--accent">зупинити</strong> це свавілля, тисни!
              </p>
              <a
                class="btn btn--orange fs-third__btn"
                href="https://vakcynacia.com.ua/ua/map"
                @click="btnVaccineClick()"
              >
                Мої щеплення
                <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 6.09111C0.498032 6.09111 0.0911068 6.49803 0.0911068 7C0.0911067 7.50197 0.498032 7.90889 1 7.90889L1 6.09111ZM19.6427 7.64269C19.9976 7.28774 19.9976 6.71226 19.6427 6.35732L13.8585 0.573156C13.5036 0.21821 12.9281 0.21821 12.5732 0.573156C12.2182 0.9281 12.2182 1.50358 12.5732 1.85852L17.7146 7L12.5732 12.1415C12.2182 12.4964 12.2182 13.0719 12.5732 13.4268C12.9281 13.7818 13.5036 13.7818 13.8585 13.4268L19.6427 7.64269ZM1 7.90889L19 7.90889L19 6.09111L1 6.09111L1 7.90889Z" fill="white"/>
                </svg>
              </a>
            </div>
            <div class="risk__rotate-text bold">
                Крутіть, щоб прибрати звук!
                <svg width="83" height="74" viewBox="0 0 83 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M61.0302 72.8688C61.5243 73.1155 62.1249 72.9149 62.3715 72.4207C62.6182 71.9266 62.4176 71.3261 61.9234 71.0794L61.0302 72.8688ZM65.9622 36.1987L66.9342 36.4337L65.9622 36.1987ZM35.9941 49.1568L35.5465 50.0511L35.5465 50.0511L35.9941 49.1568ZM13.1697 19.8427C12.6686 19.6106 12.0742 19.8287 11.8421 20.3299L8.05979 28.4965C7.82769 28.9977 8.0458 29.5921 8.54694 29.8242C9.04809 30.0563 9.64251 29.8382 9.8746 29.337L13.2366 22.0778L20.4959 25.4398C20.997 25.6719 21.5914 25.4538 21.8235 24.9527C22.0556 24.4515 21.8375 23.8571 21.3364 23.625L13.1697 19.8427ZM61.9234 71.0794C44.8158 62.5396 38.3174 54.0076 36.7128 46.8005C35.1071 39.5886 38.321 33.3458 41.4922 29.1937L39.9027 27.9797C36.586 32.3225 32.9662 39.1758 34.7606 47.2351C36.5559 55.2991 43.6911 64.2135 61.0302 72.8688L61.9234 71.0794ZM41.4922 29.1937C44.6571 25.0496 47.923 22.5703 51.0184 21.4531C54.0922 20.3437 57.0497 20.5559 59.7065 21.8855L60.6016 20.097C57.4457 18.5176 53.9188 18.2801 50.3394 19.5719C46.7816 20.856 43.2256 23.629 39.9027 27.9797L41.4922 29.1937ZM59.7065 21.8855C62.3874 23.2272 64.0491 25.2098 64.8821 27.5853C65.723 29.9835 65.7422 32.8533 64.9902 35.9637L66.9342 36.4337C67.7498 33.0601 67.7713 29.7807 66.7694 26.9235C65.7596 24.0435 63.7333 21.6643 60.6016 20.097L59.7065 21.8855ZM64.9902 35.9637C62.034 48.1909 47.5707 53.8322 36.4416 48.2625L35.5465 50.0511C47.6682 56.1175 63.6356 50.077 66.9342 36.4337L64.9902 35.9637ZM36.4416 48.2625C26.4687 43.2715 21.8412 39.0143 19.1825 34.757C17.8461 32.6171 16.982 30.4375 16.1971 28.0797C15.4255 25.7623 14.6963 23.1528 13.6883 20.4057L11.8107 21.0946C12.8131 23.8266 13.4795 26.2488 14.2995 28.7115C15.1059 31.1339 16.029 33.483 17.4861 35.8163C20.4143 40.5053 25.3986 44.9724 35.5465 50.0511L36.4416 48.2625Z" fill="#1D9E9E"/>
                </svg>
            </div>
            <div class="risk__rotate-item">
              <svg viewBox="0 0 59 147" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.92 16.7979H43.0798L48.9998 110.037H12L17.92 16.7979Z" fill="white"/>
                <path d="M31 17H43L49 110H31V17Z" fill="#F2F2F2"/>
                <g filter="url(#filter0_d)">
                    <circle cx="30.4999" cy="109.297" r="18.4999" fill="#DEDEDE"/>
                </g>
                <g filter="url(#filter1_d)" class="risk__rotate-circle">
                    <circle cx="30.4998" cy="17.5799" r="12.5799" fill="#F05745"/>
                </g>
                <g filter="url(#filter2_f)">
                 <path fill-rule="evenodd" clip-rule="evenodd" d="M35.6321 6.2684C34.5247 5.98457 33.3579 5.85301 32.1555 5.89629C25.3315 6.14192 19.9444 11.9256 20.1231 18.8146C20.2515 23.7638 23.2175 27.937 27.4081 29.8036C22.2309 28.4767 18.3526 23.822 18.2054 18.1469C18.0267 11.258 23.4138 5.47428 30.2378 5.22865C32.1592 5.15949 33.9897 5.53679 35.6321 6.2684Z" fill="#FFB8AF"/>
                </g>
                <g filter="url(#filter3_f)" >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.787 7.3999C39.5182 8.99232 40.8331 11.1096 41.4722 13.5944C43.1961 20.2966 39.3508 27.1346 32.8835 28.8676C29.1291 29.8736 25.3164 28.938 22.4411 26.6649C25.375 29.3248 29.4827 30.4895 33.5232 29.4068C39.9905 27.6738 43.8368 20.8394 42.114 14.1416C41.3984 11.3595 39.8345 9.03854 37.787 7.3999Z" fill="#B72E1E"/>
                </g>
                <defs>
                    <filter id="filter0_d" x="0" y="87.7974" width="58.9998" height="58.9998" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="-1" dy="8"/>
                        <feGaussianBlur stdDeviation="5.5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.8125 0 0 0 0 0.585716 0 0 0 0 0.494271 0 0 0 0.82 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    <filter id="filter1_d" x="8.91992" y="0" width="43.1598" height="43.1598" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="4.5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.941176 0 0 0 0 0.341176 0 0 0 0 0.270588 0 0 0 0.75 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    <filter id="filter2_f" x="17.4612" y="4.48071" width="18.911" height="26.063" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="0.369998" result="effect1_foregroundBlur"/>
                    </filter>
                    <filter id="filter3_f" x="21.7014" y="6.65991" width="21.5642" height="23.8893" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="0.369998" result="effect1_foregroundBlur"/>
                    </filter>
                </defs>
            </svg>
            </div>
            <!-- <img src="../../assets/images/rotate.svg" alt="rotate" class="risk__rotate-item"> -->
            <div class="risk__volume-control">
                <div class="risk__volume-inner">
                    <div class="risk__volume-status" ref="status">

                    </div>
                </div>
                <svg class="risk__volume-icon" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.0171 21.3927L24.2397 19.2047C24.1777 19.1691 24.1092 19.1461 24.0383 19.137C23.9673 19.1279 23.8953 19.1329 23.8263 19.1517C23.7573 19.1705 23.6927 19.2028 23.6361 19.2467C23.5795 19.2905 23.5321 19.3452 23.4966 19.4074L22.8151 20.5924C22.6644 20.8535 22.7534 21.1901 23.0137 21.3412L26.7911 23.5292C26.8532 23.5649 26.9216 23.5879 26.9925 23.597C27.0635 23.6061 27.1355 23.6011 27.2045 23.5823C27.2735 23.5635 27.3382 23.5312 27.3947 23.4873C27.4513 23.4434 27.4987 23.3888 27.5342 23.3266L28.2158 22.1415C28.3664 21.8805 28.274 21.5439 28.0171 21.3927ZM23.4932 7.8663C23.5287 7.92855 23.5761 7.98318 23.6327 8.02705C23.6892 8.07093 23.7539 8.1032 23.8229 8.12202C23.8919 8.14083 23.9639 8.14583 24.0348 8.13673C24.1058 8.12763 24.1742 8.1046 24.2363 8.06896L28.0137 5.88096C28.274 5.72982 28.363 5.39321 28.2123 5.13216L27.5342 3.95057C27.4987 3.88832 27.4513 3.83369 27.3947 3.78982C27.3382 3.74594 27.2735 3.71367 27.2045 3.69485C27.1355 3.67603 27.0635 3.67103 26.9925 3.68014C26.9216 3.68924 26.8532 3.71227 26.7911 3.74791L23.0137 5.93591C22.8886 6.00904 22.7975 6.12887 22.7603 6.26919C22.7231 6.40952 22.7428 6.55891 22.8151 6.68471L23.4932 7.8663ZM29.4521 12.4003H25.0685C24.7671 12.4003 24.5205 12.6476 24.5205 12.9499V14.3238C24.5205 14.6261 24.7671 14.8734 25.0685 14.8734H29.4521C29.7534 14.8734 30 14.6261 30 14.3238V12.9499C30 12.6476 29.7534 12.4003 29.4521 12.4003ZM18.9007 0.000488281C18.6986 0.000488281 18.4932 0.055446 18.3048 0.182536L6.16438 8.14109H0.547945C0.246575 8.14109 0 8.3884 0 8.69067V18.583C0 18.8853 0.246575 19.1326 0.547945 19.1326H6.16438L18.3048 27.0912C18.4932 27.2148 18.7021 27.2732 18.9007 27.2732C19.4726 27.2732 20 26.8164 20 26.1706V1.10308C20 0.457324 19.4726 0.000488281 18.9007 0.000488281Z" fill="#E4A68D"/>
                </svg>
            </div>
            <transition name="fade-right">
              <img src="../../assets/images/risk-2-character.png" alt="Вітроля" class="risk-second__character" v-if="scared">
            </transition>
            <img src="../../assets/images/risk-volume-shadow.png" alt="shadow" class="risk__volume-shadow">
        </section>
        <div class="show-mobile">
          <inline-svg class="pimple pimple-1-sm scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>
          <inline-svg class="pimple pimple-2-sm scar-small-mobile" :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-3-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-4-sm pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-5-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        </div>
        <ScrollToBottom @go-next-section="$emit('go-next-section', 'FaqFirstScreen')" />
      </LayoutSecond>
    </div>
</template>

<script>
import {Howl, Howler} from 'howler';
import Haha from '../../assets/sounds/haha.mp3';
import scrollToMixin from '../../mixins/scrollToMixin';
import LayoutSecond from '../../components/LayoutSecond';
import Propeller from '../../utils/propeller';
import actionOnScroll from '../../utils/actionOnScroll';

    export default {
        name: "RiskGroupsSecondScreen",
        components: {
          LayoutSecond
        },
        mixins: [scrollToMixin],
        props: {
          navigating: {
            type: Boolean
          }
        },
        data() {
          return {
            currentAngle: 0,
            angleCoef: 0,
            status: 100,
            volume: 1,
            scared: false,
            sound: null
          }
        },
        mounted() {
          const self = this;
          new Propeller('.risk__rotate-item', {
            inertia: 0, 
            speed: 0, 
            step: 20, 
            stepTransitionTime: 50,
            onRotate: function() {
              self.currentAngle = this.angle;
            }
          });
          this.sound = new Howl({
                src: [Haha],
                html5: false,
                loop: true,
                format: ['mp3']
          });
 
          actionOnScroll('.risk__rotate-text', this.initAnim)
        },
        methods: {
          changeStatus() {
            this.$refs.status.style.height = `${this.status}%`;
          },
          changeVolume() {
            // eslint-disable-next-line no-undef
            gtag('event', 'click', { 'event_category': 'main_btn_voice'}) 
            Howler.volume(this.volume);
          },
          incStatus(perc) {
            const newStatus = this.status + perc;
            if(newStatus <= 100 && newStatus >= 0) {
              this.status = newStatus;
            }
          },
          incVolume(num) {
            const newVolume = +(this.volume + num).toFixed(2);
            if(newVolume <= 1 && newVolume >= 0) {
              this.volume = newVolume;
            }
          },
          playAudio() {
            Howler.volume(1);
            this.sound.play();
          },
          initAnim() {
            setTimeout(() => {
              this.scared = true;
              this.playAudio();
            }, 500)
          },
          btnVaccineClick() {
            gtag("event", "click", {
              event_category: "Кнопка 'Мої щеплення'",
              event_label: 'Зненацька ПК'
            });
          }
        },
        watch: {
          currentAngle(angle) {
            const newCoef = Math.floor(angle / 360);
            if(this.angleCoef != newCoef) {    
              if(this.angleCoef < newCoef) {
                this.incStatus(-10);
                this.incVolume(-0.1);
              } else if(this.angleCoef > newCoef) {
                this.incStatus(10);
                this.incVolume(0.1);
              } 
              this.angleCoef = newCoef;
              this.changeStatus();
              this.changeVolume();
            }
          },
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';
  .pimple {
    @media screen and (max-width: $mobile) {
      &-1-sm {
        right: vwm(18px);
        top: vwm(105px);
      }
      
      &-2-sm {
        right: vwm(39px);
        top: vwm(159px);
      }
      
      &-3-sm {
        right: vwm(120px);
        top: vwm(20px);
      }
      
      &-4-sm {
        right: vwm(130px);
        top: vwm(40px);
      }
      
      &-5-sm {
        right: vwm(96px);
        top: vwm(47px);
      }
    }
  }  
    .section__wrapper {
        @media screen and (max-width: $mobile) {
            padding-top: vwm(41px);
        }
    }
</style>