<template>
    <div class="container" id="fact_5" data-section="FactsFifthScreen">
      <div class="hidden-group" v-if="!isMobile()">
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
      </div>
      <LayoutSecond>
        <section class="content-move">
          <img src="../../assets/images/facts-5-character.png" alt="Вітроля" class="facts-fifth__character">
          <div class="card facts__card facts-fifth__card facts__card-mobile">
            <div class="facts__card-step">
                5.
            </div>
            <p class="facts__card-text">
                Лишаюсь  із своїми фанатами
                <span class="marked bold--accent">назавжди</span>
                Я «сплю» в організмі і можу «прокинутись» у вигляді оперізуючого герпесу, коли імунітет ослабне<sup>1</sup>
            </p>
          </div>
        </section>
        <div class="show-mobile">
          <inline-svg class="pimple pimple-1-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-3-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-8-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-6-sm pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-7-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-2-sm pimple-extra-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-4-sm scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>
          <inline-svg class="pimple pimple-5-sm scar-small-mobile" :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg>
        </div>
        <ScrollToBottom @go-next-section="$emit('go-next-section', 'Vaccination')"/>
      </LayoutSecond>
    </div>
</template>

<script>
import scrollToMixin from '../../mixins/scrollToMixin';
import LayoutSecond from '../../components/LayoutSecond';

    export default {
        name: "FactsFifthScreen",
        components: {
          LayoutSecond
        },
        mixins: [scrollToMixin]
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';
  .pimple {
    @media screen and (max-width: $mobile) {
      &-1-sm {
        right: vwm(37px);
        top: vwm(110px);
      }
      
      &-2-sm {
        right: vwm(80px);
        top: vwm(119px);
      }
      
      &-3-sm {
        right: vwm(54px);
        top: vwm(138px);
      }
      
      &-4-sm {
        left: vwm(23px);
        top: vwm(347px);
      }
      
      &-5-sm {
        left: vwm(38px);
        top: vwm(397px);
      }
      
      &-6-sm {
        right: vwm(60px);
        top: vwm(370px);
      }
      
      &-7-sm {
        right: vwm(50px);
        top: vwm(410px);
      }
      
      &-8-sm {
        right: vwm(86px);
        top: vwm(420px);
      } 
    }
  }
</style>