var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",attrs:{"id":"true_or_false","data-section":"FactsSixthScreen"}},[(!_vm.isMobile())?_c('div',{staticClass:"hidden-group"},[_c('inline-svg',{attrs:{"src":require('../../assets/images/pimples/default/pimple-small.svg')}}),_c('inline-svg',{attrs:{"src":require('../../assets/images/pimples/default/pimple-large.svg')}}),_c('inline-svg',{attrs:{"src":require('../../assets/images/pimples/default/pimple-regular.svg')}}),_c('inline-svg',{attrs:{"src":require('../../assets/images/pimples/default/pimple-middle.svg')}}),_c('inline-svg',{attrs:{"src":require('../../assets/images/pimples/default/pimple-large-mobile.svg')}}),_c('inline-svg',{attrs:{"src":require('../../assets/images/pimples/default/pimple-middle-mobile.svg')}}),_c('inline-svg',{attrs:{"src":require('../../assets/images/pimples/default/scar-left.svg')}}),_c('inline-svg',{attrs:{"src":require('../../assets/images/pimples/default/scar-right.svg')}}),_c('inline-svg',{attrs:{"src":require('../../assets/images/pimples/default/scar.svg')}}),_c('inline-svg',{attrs:{"src":require('../../assets/images/pimples/default/scar-small.svg')}}),_c('inline-svg',{attrs:{"src":require('../../assets/images/pimples/default/scar-small-mobile.svg')}})],1):_vm._e(),_c('LayoutSixth',[_c('section',{staticClass:"content-move"},[_c('img',{staticClass:"facts-sixth__character",attrs:{"src":require('../../assets/images/fs-4-character.png'),"alt":"Вітроля"}}),_c('div',{staticClass:"facts-sixth__content"},[_c('p',{staticClass:"facts-sixth__title extra-bold--accent"},[_vm._v(" Правда чи міф? ")])]),_c('div',{staticClass:"card facts-game__card"},[_c('div',{staticClass:"facts-game__status-bar"},_vm._l((_vm.questions.length),function(n){return _c('div',{key:n,ref:"status-circle",refInFor:true,staticClass:"facts-game__status-circle",class:[
              n - 1 == _vm.currentQuestionIndex && !_vm.hasAnswered
                ? 'facts-game__status-circle--current'
                : '',
              _vm.questions[n - 1].answeredRight !== null &&
              _vm.questions[n - 1].answeredRight
                ? 'facts-game__status-circle--right'
                : '',
              _vm.questions[n - 1].answeredRight !== null &&
              !_vm.questions[n - 1].answeredRight
                ? 'facts-game__status-circle--wrong'
                : '' ],on:{"click":function($event){return _vm.getAnswer(n - 1)}}},[_c('div',{staticClass:"facts-game__status-circle-inner"})])}),0),_c('p',{staticClass:"facts-game__answer-count"},[_c('span',[_vm._v(_vm._s(_vm.currentQuestionIndex + 1))]),_vm._v(" / "+_vm._s(_vm.questions.length)+" ")]),_c('div',{staticClass:"facts-game__question"},[_c('h6',[_vm._v(" "+_vm._s(_vm.currentQuestion.text)+" ")])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasAnswered || _vm.goToPrev),expression:"hasAnswered || goToPrev"}],staticClass:"facts-game__answer",class:[
            _vm.currentQuestion.answeredRight
              ? 'facts-game__answer--right'
              : 'facts-game__answer--wrong' ]},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.currentQuestion.answerPerfix)+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.currentQuestion.answer)}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.hasAnswered &&
              _vm.currentQuestionIndex + 1 !== _vm.questions.length &&
              !_vm.goToPrev
          ),expression:"\n            hasAnswered &&\n              currentQuestionIndex + 1 !== questions.length &&\n              !goToPrev\n          "}],staticClass:"facts-game__button-next"},[_c('button',{staticClass:"btn btn--facts",on:{"click":_vm.nextQuestion}},[_vm._v(" Наступний ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasAnswered && !_vm.goToPrev),expression:"!hasAnswered && !goToPrev"}],staticClass:"facts-game__buttons-pick"},[_c('button',{staticClass:"btn btn--facts",on:{"click":function($event){return _vm.answerPickHandler('truth')}}},[_vm._v(" Правда ")]),_c('button',{staticClass:"btn btn--facts",on:{"click":function($event){return _vm.answerPickHandler('myth')}}},[_vm._v(" Міф ")])])])]),_c('div',{staticClass:"show-mobile"},[_c('inline-svg',{staticClass:"pimple pimple-1-sm pimple-small",attrs:{"src":require('../../assets/images/pimples/default/pimple-small.svg')}}),_c('inline-svg',{staticClass:"pimple pimple-2-sm pimple-extra-small",attrs:{"src":require('../../assets/images/pimples/default/pimple-small.svg')}}),_c('inline-svg',{staticClass:"pimple pimple-3-sm pimple-small",attrs:{"src":require('../../assets/images/pimples/default/pimple-small.svg')}}),_c('inline-svg',{staticClass:"pimple pimple-4-sm scar-left",attrs:{"src":require('../../assets/images/pimples/default/scar-left.svg')}}),_c('inline-svg',{staticClass:"pimple pimple-5-sm scar-small-mobile",attrs:{"src":require('../../assets/images/pimples/default/scar-small-mobile.svg')}})],1),_c('ScrollToBottom',{on:{"go-next-section":function($event){return _vm.$emit('go-next-section', 'FactsFirstScreen')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }