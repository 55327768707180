<template>
  <div class="container" id="true_or_false" data-section="FactsSixthScreen">
    <div class="hidden-group" v-if="!isMobile()">
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-small.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-large.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-regular.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-middle.svg')"
      ></inline-svg>
      <inline-svg
        :src="
          require('../../assets/images/pimples/default/pimple-large-mobile.svg')
        "
      ></inline-svg>
      <inline-svg
        :src="
          require('../../assets/images/pimples/default/pimple-middle-mobile.svg')
        "
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar-left.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar-right.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar-small.svg')"
      ></inline-svg>
      <inline-svg
        :src="
          require('../../assets/images/pimples/default/scar-small-mobile.svg')
        "
      ></inline-svg>
    </div>
    <LayoutSixth>
      <section class="content-move">
        <img
          :src="require('../../assets/images/fs-4-character.png')"
          alt="Вітроля"
          class="facts-sixth__character"
        />
        <div class="facts-sixth__content">
          <p class="facts-sixth__title extra-bold--accent">
            Правда чи міф?
          </p>
        </div>
        <div class="card facts-game__card">
          <div class="facts-game__status-bar">
            <div
              class="facts-game__status-circle"
              :class="[
                n - 1 == currentQuestionIndex && !hasAnswered
                  ? 'facts-game__status-circle--current'
                  : '',
                questions[n - 1].answeredRight !== null &&
                questions[n - 1].answeredRight
                  ? 'facts-game__status-circle--right'
                  : '',
                questions[n - 1].answeredRight !== null &&
                !questions[n - 1].answeredRight
                  ? 'facts-game__status-circle--wrong'
                  : '',
              ]"
              ref="status-circle"
              v-for="n in questions.length"
              @click="getAnswer(n - 1)"
              :key="n"
            >
              <div class="facts-game__status-circle-inner"></div>
            </div>
          </div>
          <p class="facts-game__answer-count">
            <span>{{ currentQuestionIndex + 1 }}</span> / {{ questions.length }}
          </p>
          <div class="facts-game__question">
            <h6>
              {{ currentQuestion.text }}
            </h6>
          </div>
          <p
            class="facts-game__answer"
            :class="[
              currentQuestion.answeredRight
                ? 'facts-game__answer--right'
                : 'facts-game__answer--wrong',
            ]"
            v-show="hasAnswered || goToPrev"
          >
            <span class="bold">{{ currentQuestion.answerPerfix }} </span>
            <span v-html="currentQuestion.answer"></span>
          </p>
          <div
            class="facts-game__button-next"
            v-show="
              hasAnswered &&
                currentQuestionIndex + 1 !== questions.length &&
                !goToPrev
            "
          >
            <button class="btn btn--facts" @click="nextQuestion">
              Наступний
            </button>
          </div>
          <div
            class="facts-game__buttons-pick"
            v-show="!hasAnswered && !goToPrev"
          >
            <button class="btn btn--facts" @click="answerPickHandler('truth')">
              Правда
            </button>
            <button class="btn btn--facts" @click="answerPickHandler('myth')">
              Міф
            </button>
          </div>
        </div>
      </section>
      <div class="show-mobile">
        <inline-svg
          class="pimple pimple-1-sm pimple-small"
          :src="require('../../assets/images/pimples/default/pimple-small.svg')"
        ></inline-svg>
        <inline-svg
          class="pimple pimple-2-sm pimple-extra-small"
          :src="require('../../assets/images/pimples/default/pimple-small.svg')"
        ></inline-svg>
        <inline-svg
          class="pimple pimple-3-sm pimple-small"
          :src="require('../../assets/images/pimples/default/pimple-small.svg')"
        ></inline-svg>
        <inline-svg
          class="pimple pimple-4-sm scar-left"
          :src="require('../../assets/images/pimples/default/scar-left.svg')"
        ></inline-svg>
        <inline-svg
          class="pimple pimple-5-sm scar-small-mobile"
          :src="
            require('../../assets/images/pimples/default/scar-small-mobile.svg')
          "
        ></inline-svg>
      </div>
      <ScrollToBottom
        @go-next-section="$emit('go-next-section', 'FactsFirstScreen')"
      />
    </LayoutSixth>
  </div>
</template>

<script>
import scrollToMixin from "../../mixins/scrollToMixin";
import LayoutSixth from "../../components/LayoutSixth";

export default {
  name: "FactsSixthScreen",
  mixins: [scrollToMixin],
  components: {
    LayoutSixth,
  },
  data() {
    return {
      currentQuestionIndex: 0,
      goToIndex: 0,
      hasAnswered: false,
      answerPicked: null,
      goToPrev: false,
      questions: [
        {
          text: "З початком шкільного сезону, ризик заразитись вітрянкою стає вищим.",
          isTruth: true,
          answer: `Ризик зараження вітрянкою зростає в організованих дитячих колективах, особливо в дитячих садочках та школах.`,
          answeredRight: null,
          answerPrefix: "",
        },
        {
          text: "Щеплення проти вітряної віспи можна проводити разом з іншими щепленнями.",
          isTruth: true,
          answer: `Вакцинацію від вітрянки можна поєднувати разом з іншими плановими щеплення. З якими саме – запитайте у свого лікаря.`,
          answeredRight: null,
          answerPrefix: "",
        },
        {
          text: "Вітрянкою можна захворіти лише раз в житті",
          isTruth: false,
          answer: `Іноді трапляються повторні випадки зараження, хоча вони й протікають легше<sup>1</sup>.`,
          answeredRight: null,
          answerPrefix: "",
        },
        {
          text: "Вітрянка у дітей проходить без ускладнень",
          isTruth: false,
          answer: `Приблизно 2-3 із 1000 дітей, хворих на вітрянку, потребують госпіталізації<sup>2</sup>. 
                            До найтяжчих ускладнень відносяться пневмонія та енцефаліт<sup>1</sup>.`,
          answeredRight: null,
          answerPrefix: "",
        },
        {
          text: "Вітрянка – це дитяча хвороба",
          isTruth: false,
          answer: `Хвороба поширена в організованих дитячих колективах. Найчастіше хворіють діти у віці від 3 до 6 років, рідше -  
                            малюки до року. Але хворіти можуть люди будь-якого віку`,
          answeredRight: null,
          answerPrefix: "",
        },
        {
          text: "Пухирці з’являються не лише на шкірі",
          isTruth: true,
          answer: `Пухирці з’являються не лише на шкірі<sup>1</sup>.`,
          answeredRight: null,
          answerPrefix: "",
        },
        {
          text: "Вакцинацією можна захиститися від вітрянки",
          isTruth: true,
          answer: `Вакцинація ефективна у 94,4% випадків після введення 1 дози і у 98,3% після введення 2 доз<sup>1</sup>.`,
          answeredRight: null,
          answerPrefix: "",
        },
      ],
    };
  },
  computed: {
    currentQuestion() {
      const question = this.questions[this.goToIndex];
      if (this.goToIndex == this.currentQuestionIndex) {
        if (this.answerPicked === "truth" && question.isTruth) {
          question.answerPerfix = "Правда!";
          question.answeredRight = true;
        } else if (this.answerPicked === "truth" && !question.isTruth) {
          question.answerPerfix = "Ні, це міф!";
          question.answeredRight = false;
        } else if (this.answerPicked === "myth" && question.isTruth) {
          question.answerPerfix = "Ні, це правда!";
          question.answeredRight = false;
        } else if (this.answerPicked === "myth" && !question.isTruth) {
          question.answerPerfix = "Так, це міф!";
          question.answeredRight = true;
        }
      }

      return { ...question };
    },
  },
  methods: {
    /* eslint-disable no-undef */
    answerPickHandler(choice) {
      if (!choice || (choice !== "truth" && choice !== "myth")) return;
      this.answerPicked = choice;
      this.hasAnswered = true;

      if (choice === "truth" && this.currentQuestionIndex === 0) {
        gtag("event", "click", { event_category: " q1_choice_true" });
      } else if (choice === "truth" && this.currentQuestionIndex === 1) {
        gtag("event", "click", { event_category: " q2_choice_true" });
      } else if (choice === "truth" && this.currentQuestionIndex === 2) {
        gtag("event", "click", { event_category: " q3_choice_true" });
      } else if (choice === "truth" && this.currentQuestionIndex === 3) {
        gtag("event", "click", { event_category: " q4_choice_true" });
      } else if (choice === "truth" && this.currentQuestionIndex === 4) {
        gtag("event", "click", { event_category: " q5_choice_true" });
      } else if (choice === "truth" && this.currentQuestionIndex === 5) {
        gtag("event", "click", { event_category: " q6_choice_true" });
      } else if (choice === "truth" && this.currentQuestionIndex === 6) {
        gtag("event", "click", { event_category: " q7_choice_true" });
      }

      if (choice === "myth" && this.currentQuestionIndex === 0) {
        gtag("event", "click", { event_category: " q1_choice_false" });
      } else if (choice === "myth" && this.currentQuestionIndex === 1) {
        gtag("event", "click", { event_category: " q2_choice_false" });
      } else if (choice === "myth" && this.currentQuestionIndex === 2) {
        gtag("event", "click", { event_category: " q3_choice_false" });
      } else if (choice === "myth" && this.currentQuestionIndex === 3) {
        gtag("event", "click", { event_category: " q4_choice_false" });
      } else if (choice === "myth" && this.currentQuestionIndex === 4) {
        gtag("event", "click", { event_category: " q5_choice_false" });
      } else if (choice === "myth" && this.currentQuestionIndex === 5) {
        gtag("event", "click", { event_category: " q6_choice_false" });
      } else if (choice === "myth" && this.currentQuestionIndex === 6) {
        gtag("event", "click", { event_category: " q7_choice_false" });
      }
    },

    nextQuestion() {
      this.answerPicked = null;
      this.hasAnswered = false;
      if (this.currentQuestionIndex === 0) {
        gtag("event", "click", { event_category: " q1_choice_next" });
      } else if (this.currentQuestionIndex === 1) {
        gtag("event", "click", { event_category: " q2_choice_next" });
      } else if (this.currentQuestionIndex === 2) {
        gtag("event", "click", { event_category: " q3_choice_next" });
      } else if (this.currentQuestionIndex === 3) {
        gtag("event", "click", { event_category: " q4_choice_next" });
      } else if (this.currentQuestionIndex === 4) {
        gtag("event", "click", { event_category: " q5_choice_next" });
      } else if (this.currentQuestionIndex === 5) {
        gtag("event", "click", { event_category: " q6_choice_next" });
      } else if (this.currentQuestionIndex === 6) {
        gtag("event", "click", { event_category: " q7_choice_next" });
      }
      ++this.currentQuestionIndex;
      ++this.goToIndex;
    },

    getAnswer(idx) {
      if (idx > this.currentQuestionIndex) {
        return;
      } else if (idx == this.currentQuestionIndex) {
        this.goToIndex = this.currentQuestionIndex;
        this.goToPrev = false;
      } else {
        this.goToIndex = idx;
        this.goToPrev = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/vars";
@import "../../scss/mixin";
.pimple {
  @media screen and (max-width: $mobile) {
    &-1-sm {
      right: vwm(50px);
      top: vwm(86px);
    }

    &-2-sm {
      right: vwm(95px);
      top: vwm(93px);
    }

    &-3-sm {
      right: vwm(62px);
      top: vwm(109px);
    }

    &-4-sm {
      right: vwm(18px);
      bottom: vwm(151px);
    }

    &-5-sm {
      right: vwm(26px);
      bottom: vwm(104px);
    }
  }
}
</style>
