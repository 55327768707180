<template>
  <div>
    <svg
      class="ham hamRotate"
      :class="{ active: menuOpened }"
      viewBox="0 0 100 100"
      width="50"
      @click="toggleMenu"
    >
      <path
        class="line top"
        d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
      />
      <path class="line middle" d="m 30,50 h 40" />
      <path
        class="line bottom"
        d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
      />
    </svg>
    <aside class="side-menu" :class="{ open: menuOpened }">
      <nav>
        <ul class="menu">
          <li
            class="menu__item"
            v-for="(link, index) in menuLinks"
            :key="link.url"
            :class="{ 'menu__item--active': link.isActive }"
          >
            <nobr>
              <a
                :href="link.url"
                class="menu__link"
                :class="{ 'menu__link--active': link.isActive }"
                :data-screen="link.screenName"
                :data-index="link.index"
                @click.prevent="navigateTo($event, index)"
              >

                  <img :src="require(`@/assets/images/${link.img}`)" alt="" v-if="link.img">
                  {{ link.text }}
              </a>
            </nobr>
          </li>
        </ul>
      </nav>
      <div class="side-menu__buttons">
        <!-- <button
          class="btn btn--white side-menu__btn side-menu__btn-socials"
          @click="btnSocialClick()"
        >
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7651 5.54525L13.265 9.79478C12.7906 10.2428 12 9.91063 12 9.24953V7.00069C7.48203 7.031 5.57606 8.09797 6.85078 12.3555C6.99088 12.8234 6.44934 13.1857 6.06934 12.897C4.85162 11.972 3.75 10.2028 3.75 8.41685C3.75 3.91882 7.42497 3.02622 12 3.00085V0.750382C12 0.0886945 12.7912 -0.242368 13.265 0.205132L17.7651 4.45476C18.0782 4.75047 18.0784 5.24929 17.7651 5.54525ZM12 11.8478V14H2V4.00001H3.59113C3.64162 3.99997 3.69159 3.98973 3.73804 3.9699C3.78448 3.95007 3.82643 3.92105 3.86138 3.8846C4.32866 3.3981 4.86878 3.01304 5.45556 2.70722C5.80338 2.52594 5.6745 2.00001 5.28228 2.00001H1.5C0.671562 2.00001 0 2.67157 0 3.50001V14.5C0 15.3284 0.671562 16 1.5 16H12.5C13.3284 16 14 15.3284 14 14.5V11.7248C14 11.4658 13.7438 11.2853 13.4997 11.3716C13.1573 11.4927 12.7907 11.5289 12.4312 11.4772C12.2041 11.4445 12 11.6182 12 11.8478Z"
              fill="white"
            />
          </svg>
          Заразити соцмережі!
        </button> -->
        <a
          class="btn side-menu__btn btn--orange btn--newStyle  side-menu__newStyle"
          href="https://vakcynacia.com.ua/ua/map"
          target="_blank"
          @click="btnCheckSiteClick()">

          <svg width="22" height="22" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5976 5.02702C13.8876 2.79279 17.1124 2.79279 18.4024 5.02703L26.021 18.223C27.311 20.4572 25.6986 23.25 23.1187 23.25H7.88131C5.30144 23.25 3.68903 20.4572 4.97896 18.223L12.5976 5.02702Z" fill="white"/>
            <path d="M15.771 16.6098C15.6102 16.6098 15.4709 16.5562 15.353 16.449C15.2459 16.3419 15.1816 16.1865 15.1601 15.9829L14.5654 9.73009L14.5493 9.56935C14.5493 9.23715 14.6618 8.9746 14.8869 8.78172C15.1226 8.57811 15.4173 8.47631 15.771 8.47631C16.146 8.47631 16.4514 8.59418 16.6872 8.82994C16.9229 9.05497 17.0194 9.36038 16.9765 9.74616L16.3818 15.9829C16.3603 16.1865 16.2907 16.3419 16.1728 16.449C16.0656 16.5562 15.9317 16.6098 15.771 16.6098ZM15.771 19.9853C15.428 19.9853 15.1441 19.8728 14.919 19.6478C14.694 19.412 14.5815 19.1227 14.5815 18.7798C14.5815 18.4369 14.694 18.1529 14.919 17.9279C15.1441 17.6921 15.428 17.5742 15.771 17.5742C16.1139 17.5742 16.3978 17.6921 16.6229 17.9279C16.8479 18.1529 16.9604 18.4369 16.9604 18.7798C16.9604 19.1227 16.8479 19.412 16.6229 19.6478C16.3978 19.8728 16.1139 19.9853 15.771 19.9853Z" fill="#F05745"/>
          </svg>

          <span class="new-text"><span class="big">ОБЕРЕЖНО!</span><br>Інформація про щеплення</span>

        </a>

        <a
          href="https://vakcynacia.com.ua/promo/5.html"
          class="side-menu__vebinar vebinar"
          target="_blank"
        >
          <p class="vebinar__title">
            <strong>Вітряна віспа:</strong><br> доступно <br>про важливе
          </p>
          <p class="vebinar__text">
            Доповідач: <strong>Наталія Васецька</strong>, <br>медичний менеджер GSK, вакцини
          </p>
          <button
            class="vebinar__btn btn btn--green"
            @click="btnVebinarClick()"
          >
            Перейти до вебінару
            <span>«Вітряна віспа: доступно про важливе»</span>
          </button>
        </a>
      </div>
      <div class="show-mobile">
        <inline-svg
          :src="
            require('../assets/images/pimples/default/pimple-large-mobile.svg')
          "
          class="pimple pimple-1-sm"
        ></inline-svg>
        <inline-svg
          :src="require('../assets/images/pimples/default/scar.svg')"
          class="pimple pimple-2-sm"
        ></inline-svg>
        <inline-svg
          :src="require('../assets/images/pimples/default/pimple-small.svg')"
          class="pimple pimple-3-sm"
        ></inline-svg>
        <inline-svg
          :src="require('../assets/images/pimples/default/pimple-small.svg')"
          class="pimple pimple-4-sm"
        ></inline-svg>
        <inline-svg
          :src="require('../assets/images/pimples/default/pimple-regular.svg')"
          class="pimple pimple-5-sm"
        ></inline-svg>
      </div>
    </aside>
    <nav>
      <ul class="mobile-menu">
        <li class="mobile-menu__item" v-for="link in menuLinks" :key="link.url">
          <a
            :href="link.url"
            class="mobile-menu__link"
            :class="{ 'mobile-menu__link--active': link.isActive }"
            @click.prevent="scrollToScreen($event)"
          >
          </a>
        </li>
        <a
          class="btn mobile-menu__btn btn--orange btn--newStyle side-menu__btn side-menu__newStyle"
          href="https://vakcynacia.com.ua/ua/map"
          target="_blank"
          @click="btnCheckSiteClick()">

          <svg width="22" height="22" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5976 5.02702C13.8876 2.79279 17.1124 2.79279 18.4024 5.02703L26.021 18.223C27.311 20.4572 25.6986 23.25 23.1187 23.25H7.88131C5.30144 23.25 3.68903 20.4572 4.97896 18.223L12.5976 5.02702Z" fill="white"/>
            <path d="M15.771 16.6098C15.6102 16.6098 15.4709 16.5562 15.353 16.449C15.2459 16.3419 15.1816 16.1865 15.1601 15.9829L14.5654 9.73009L14.5493 9.56935C14.5493 9.23715 14.6618 8.9746 14.8869 8.78172C15.1226 8.57811 15.4173 8.47631 15.771 8.47631C16.146 8.47631 16.4514 8.59418 16.6872 8.82994C16.9229 9.05497 17.0194 9.36038 16.9765 9.74616L16.3818 15.9829C16.3603 16.1865 16.2907 16.3419 16.1728 16.449C16.0656 16.5562 15.9317 16.6098 15.771 16.6098ZM15.771 19.9853C15.428 19.9853 15.1441 19.8728 14.919 19.6478C14.694 19.412 14.5815 19.1227 14.5815 18.7798C14.5815 18.4369 14.694 18.1529 14.919 17.9279C15.1441 17.6921 15.428 17.5742 15.771 17.5742C16.1139 17.5742 16.3978 17.6921 16.6229 17.9279C16.8479 18.1529 16.9604 18.4369 16.9604 18.7798C16.9604 19.1227 16.8479 19.412 16.6229 19.6478C16.3978 19.8728 16.1139 19.9853 15.771 19.9853Z" fill="#F05745"/>
          </svg>

          <span class="new-text"><span class="big">ОБЕРЕЖНО!</span><br>Інформація про щеплення</span>

        </a>
      </ul>
    </nav>
    <transition name="fade">
      <Overlay :show="widgetOpened">
        <Widget @click="closePopup" />
      </Overlay>
    </transition>
  </div>
</template>

<script>
import { debounce, isMobile } from "../utils/helpers";
import scrollToMixin from "../mixins/scrollToMixin";
import Overlay from "./Overlay";
import Widget from "./Widget";

export default {
  name: "Menu",
  mixins: [scrollToMixin],
  components: {
    Overlay,
    Widget,
  },
  props: {
    menuOpened: {
      type: Boolean,
    },
    navigating: {
      type: Boolean,
    },
  },
  data() {
    return {
      menuLinks: [
        {
          index: 0,
          text: "Вітроля",
          url: "#main",
          isActive: true,
          screenName: "Hero",
        },
        // {
        //   index: 1,
        //   text: "З 1-м Вірусня!",
        //   img: "menu-bell.png",
        //   url: "#first-sept_1",
        //   isActive: false,
        //   screenName: "FirstSeptFirstScreen",
        // },
        {
          index: 1,
          text: "Факти та міфи",
          url: "#true_or_false",
          isActive: false,
          screenName: "FactsSixthScreen",
        },
        {
          index: 2,
          text: "Про щеплення",
          url: "#percentage",
          isActive: false,
          screenName: "Vaccination",
        },
        {
          index: 3,
          text: "72 години",
          url: "#72_hours",
          isActive: false,
          screenName: "TimeFirstScreen",
        },
        {
          index: 4,
          text: "Групи ризику",
          url: "#group_of_risks",
          isActive: false,
          screenName: "RiskGroupsFirstScreen",
        },
        {
          index: 5,
          text: "Питання та відповіді",
          url: "#faq",
          isActive: false,
          screenName: "FaqFirstScreen",
        },
        {
          index: 6,
          text: "Історія вітрянки",
          url: "#history",
          isActive: false,
          screenName: "Footer",
        },
      ],
      widgetOpened: false,
      scrollPos: 0,
    };
  },
  mounted() {
    // document.addEventListener("scroll", debounce(this.onScroll, 100));
    
    if (isMobile()) {
      document.addEventListener("scroll", debounce(this.onScroll, 100));
      document.addEventListener("scroll", this.hideBtn());
    }
  },
  methods: {
    /* eslint-disable no-undef */
    hideBtn() {
      const st = window.scrollY;
      const btn = document.querySelector('.mobile-menu__btn');

      if (st > this.scrollPos || (st + window.innerHeight === document.body.scrollHeight)){
        btn.classList.add('hidden');
      } else {
        btn.classList.remove('hidden');
      }

      this.scrollPos = st;

      requestAnimationFrame(this.hideBtn)
    },
    onScroll() {
      const sections = document.querySelectorAll(".menu__link");
      const scrollPos =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      for (let section of sections) {
        const href = section.getAttribute("href");
        const refElement = document.getElementById(href.slice(1));
        if (refElement && refElement.offsetTop <= scrollPos + 20) {
          document
            .querySelector(".menu__link--active")
            .classList.remove("menu__link--active");
          document
            .querySelector(".menu__item--active")
            .classList.remove("menu__item--active");
          section.classList.add("menu__link--active");
          section.parentElement.classList.add("menu__item--active");
          if (window.outerWidth <= 480) {
            const mobileLink = document.querySelector(
              `.mobile-menu__link[href="${href}"]`
            );
            if (mobileLink) {
              document
                .querySelector(".mobile-menu__link--active")
                .classList.remove("mobile-menu__link--active");
              mobileLink.classList.add("mobile-menu__link--active");
            }
          }
        }
      }
      const components = document.querySelectorAll('.section');
      const visible = [];
      Array.from(components).forEach(comp => {
        if (comp.offsetTop <= scrollPos + 20) {
          visible.push(comp);
        }
      });
      const id = visible[visible.length - 1].getAttribute('id');
      window.history.replaceState({}, null, `#${id}`);
    },

    toggleMenu() {
      this.$emit("toggle-menu");
    },

    navigateTo(e, i) {
      if (isMobile()) {
        this.scrollToScreen(e);
      } else {
        if (!this.navigating) {
          const screen = e.target.dataset.screen;
          const idx = e.target.dataset.index;
          const currIdx = document.querySelector(".menu__link--active").dataset
            .index;
          if (idx == currIdx) return;
          if (idx > currIdx) {
            this.$emit("change-direction", true);
          } else {
            this.$emit("change-direction", false);
          }
          this.$emit("navigate-to-screen", screen);
          document
            .querySelector(".menu__link--active")
            .classList.remove("menu__link--active");
          e.target.classList.add("menu__link--active");
        }
      }

      if (this.menuOpened) {
        this.$emit("update:menuOpened", false);
      }

      if (i === 0) {
        gtag("event", "click", { event_category: "side_menu_sec_1" });
      } else if (i === 1) {
        gtag("event", "click", { event_category: " side_menu_sec_2" });
      } else if (i === 2) {
        gtag("event", "click", { event_category: " side_menu_sec_3" });
      } else if (i === 3) {
        gtag("event", "click", { event_category: " side_menu_sec_4" });
      } else if (i === 4) {
        gtag("event", "click", { event_category: " side_menu_sec_5" });
      } else if (i === 5) {
        gtag("event", "click", { event_category: " side_menu_sec_6" });
      } else if (i === 6) {
        gtag("event", "click", { event_category: " side_menu_sec_7" });
      // } else if (i === 7) {
      //   gtag("event", "click", { event_category: " side_menu_sec_8" });
      }
    },
    btnSocialClick() {
      this.widgetOpened = true;
      gtag("event", "click", {
        event_category: "Кнопка 'Заразити соцмережі'",
        event_label: 'Боковое меню'
      });

    },
    btnCheckSiteClick() {
      if (isMobile()) {
        gtag("event", "click", {
          event_category: "Кнопка 'Обережно! Інформація про щеплення'",
          event_label: 'Плаваюча кнопка (Моб)'
        });
      } else if (this.menuLinks[5].isActive) {
        gtag("event", "click", {
          event_category: "Кнопка 'Мої щеплення'",
          event_label: 'Зненацька Моб'
        });
      } else {
        gtag("event", "click", {
          event_category: "Кнопка 'Обережно! Інформація про щеплення'",
          event_label: 'Боковое меню'
        });
      }
    },
    btnVebinarClick() {
      gtag("event", "click", {
        event_category: "Кнопка 'Перейти до вебінару'",
        event_label: 'Боковое меню'
      });
    },
    closePopup() {
      this.widgetOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/mixin";
@import "../scss/vars";
.pimple {
  @media screen and (max-width: $mobile) {
    &-1-sm {
      width: vwm(107px);
      height: vwm(94px);
      left: vwm(50px);
      top: 0;
    }

    &-2-sm {
      width: vwm(35px);
      height: vwm(35px);
      left: vwm(33px);
      top: vwm(67px);
    }

    &-3-sm {
      width: vwm(40px);
      height: vwm(40px);
      left: vwm(80px);
      top: vwm(80px);
    }

    &-4-sm {
      width: vwm(40px);
      height: vwm(40px);
      left: vwm(40px);
      top: vwm(140px);
    }

    &-5-sm {
      width: vwm(100px);
      height: vwm(100px);
      left: vwm(80px);
      top: vwm(120px);
    }
  }
}
</style>
