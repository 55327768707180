<template>
    <div class="container" id="fact_3" data-section="FactsThirdScreen">
      <div class="hidden-group" v-if="!isMobile()">
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
      </div>
      <LayoutThird>
        <section class="content-move">
            <img src="../../assets/images/facts-3-character.png" alt="Вітроля" class="facts-third__character">
            <canvas class="canvas"></canvas>
            <div class="card facts__card facts-third__card facts__card-mobile">
              <div class="facts__card-step">
                  3.
              </div>
              <p class="facts__card-text">
                  Знаходжусь на піку популярності! Переді мною важко встояти – щороку я в тренді зі своїм показником заразності 90%<sup>2</sup>.
              </p>
            </div>
        </section>
        <div class="show-mobile">
          <inline-svg class="pimple pimple-1-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-2-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-4-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-6-sm pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-7-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-8-sm pimple-extra-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-3-sm scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        </div>
        <ScrollToBottom @go-next-section="$emit('go-next-section', 'FactsFourthScreen')" />
      </LayoutThird>
    </div>
</template>

<script>
import scrollToMixin from '../../mixins/scrollToMixin';
import S from '../../utils/shifter';
import actionOnScroll from '../../utils/actionOnScroll';
import LayoutThird from '../../components/LayoutThird';

    export default {
        name: "FactsThirdScreen",
        components: {
          LayoutThird
        },
        mixins: [scrollToMixin],
        mounted() {
          actionOnScroll('.canvas', () => S.init('90%', '.canvas'));
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';

  .canvas {
    position: absolute;
    width: 40.71875vw;
    height: 22.4375vw;
    top: -2.7vw;
    right: 11vw;
    @media screen and (min-width: $minMobile) and (max-height: $maxHeightD) {
      position: absolute;
      width: 52.71875vw;
      height: 20.4375vw;
      top: vw(-30px);
      right: 6vw;
    }
    @media screen and (max-width: $mobile) {
      bottom: vwm(-80px);
      width: vwm(280px);
      height: vwm(350px);
      right: vwm(-20px);
      top: auto;
    }
  }

  .pimple {
    @media screen and (max-width: $mobile) {
      &-1-sm {
        right: vwm(34px);
        top: vwm(110px);
      }
      
      &-2-sm {
        right: vwm(60px);
        top: vwm(135px);
      }
      
      &-3-sm {
        right: vwm(83px);
        top: vwm(357px);
      }
      
      &-4-sm {
        right: vwm(147px);
        top: vwm(386px);
      }
      
      &-5-sm {
        right: vwm(67px);
        top: vwm(418px);
      }
      
      &-6-sm {
        left: vwm(20px);
        bottom: vwm(110px);
      }
      
      &-7-sm {
        left: vwm(45px);
        bottom: vwm(80px);
      }
      
      &-8-sm {
        left: vwm(26px);
        bottom: vwm(94px);
      } 
    }
    
  }
</style>