<template>
    <div class="popup" :class="[{active: isActive}, 'gift-popup--' + id]">
        <div class="popup__top">
            <slot name="top"></slot>
            <button class="popup__close" @click="popupClose">
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.50193 1.2876H1.31738L6.33884 6.30906L1.31738 11.3305H5.50193L8.01266 8.40133L10.5234 11.3305H14.2895L9.68648 6.30906L14.2895 1.2876H10.5234L8.01266 3.79833L5.50193 1.2876Z" fill="#E0EBE5" stroke="#5E5E5E" stroke-width="0.83691"/>
                </svg>
            </button>
        </div>
        <div class="popup__main">
            <p class="popup__main-text">
                <slot name="main"></slot>
            </p>
            <div class="popup__buttons">
                <button class="popup__btn popup__ok" @click="popupClose">
                    OK
                </button>
                <button class="popup__btn popup__cancel" @click="popupClose">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Popup",
        props: {
            isActive: Boolean,
            id: Number
        },
        methods: {
            popupClose() {
                if(!this.isActive) return;
                this.$emit('popup-close');
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>