import Vue from 'vue'
import App from './App.vue'
import InlineSvg from 'vue-inline-svg';
import VueMeta from 'vue-meta';

Vue.component('inline-svg', InlineSvg);
Vue.use(VueMeta);

Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')
