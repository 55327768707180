<template functional>
    <div class="section__wrapper">
        <div class="hide-mobile">

            <inline-svg class="pimple pimple-1 pimple-large" :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
            <inline-svg class="pimple pimple-2 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-3 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-4 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

            <inline-svg class="pimple pimple-5 scar-large" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>

            <inline-svg class="pimple pimple-6 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-7 pimple-middle" :src="require('../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>

            <inline-svg class="pimple pimple-8 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-9 pimple-regular" :src="require('../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
            <inline-svg class="pimple pimple-10 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-11 pimple-large" :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
            <inline-svg class="pimple pimple-12 pimple-middle" :src="require('../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>

            <inline-svg class="pimple pimple-13 scar-small" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-14 scar-regular" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>

            <inline-svg class="pimple pimple-15 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

            <inline-svg class="pimple pimple-16 scar-middle" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-17 scar-middle" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-18 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-19 scar-right" :src="require('../assets/images/pimples/default/scar-right.svg')"></inline-svg>

            <inline-svg class="pimple pimple-20 scar-large" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-21 scar-regular" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-22 scar-small" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-23 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
      name: "LayoutFirst"
    }
</script>

<style lang="scss" scoped>
@import '../scss/mixin';
  ::v-deep .pimple {
    &-1 {
      left: vw(55px);
      top: vw(45px);
    }
    
    &-2 {
      left: vw(60px);
      top: vw(120px);
    }
    
    &-3 {
      left: vw(30px);
      top: vw(185px);
    }
    
    &-4 {
      left: vw(95px);
      top: vw(179px);
    }
    
    &-5 {
      left: vw(647px);
      top: vw(100px);
    }
    
    &-6 {
      left: vw(1030px);
      top: vw(71px);
    }
    
    &-7 {
      left: vw(1060px);
      top: vw(7px);
    }
    
    &-8 {
      right: vw(347px);
      top: vw(181px);
    } 
    
    &-9 {
      right: vw(200px);
      top: vw(120px);
    }
    
    &-10 {
      right: vw(124px);
      top: vw(196px);
    }

    &-11 {
      right: vw(370px);
      top: vw(220px);
    }
    
    &-12 {
      right: vw(200px);
      top: vw(255px);
    }
    
    &-13 {
      right: vw(729px);
      top: vw(370px);
    }
    
    &-14 {
      right: vw(564px);
      top: vw(340px);
    }
    
    &-15 {
      right: vw(319px);
      bottom: vw(466px);
    }
    
    &-16 {
      right: vw(155px);
      bottom: vw(287px);
    }

    &-17 {
      right: vw(217px);
      bottom: vw(201px);
    }
    
    &-18 {
      right: vw(330px);
      bottom: vw(186px);
    }
    
    &-19 {
      right: vw(92px);
      bottom: vw(158px);
    }
    
    &-20 {
      left: vw(175px);
      bottom: vw(126px);
    }
    
    &-21 {
      left: vw(274px);
      bottom: vw(111px);
    }
    
    &-22 {
      left: vw(142px);
      bottom: vw(115px);
    }

    &-23 {
      left: vw(168px);
      bottom: vw(38px);
    }

  }
</style>