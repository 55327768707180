<template>
    <div class="container" data-section="FactsFourthScreen" id="fact_4">
      <div class="hidden-group" v-if="!isMobile()">
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
      </div>
        <LayoutFirst>
          <section class="content-move">
                <div class="card facts__card facts-fourth__card facts__card-mobile">
                  <div class="facts__card-step">
                      4.
                  </div>
                  <p class="facts__card-text">
                      Організовую класні вечірки. Тільки уявіть, матусі збирають своїх дітей в одну 
                      компанію з хворим вітрянкою, щоб заразити їх! Ви чули таке хоч про одну хворобу?
                  </p>
                </div>
                <img src="../../assets/images/facts-4-character.png" alt="Вітроля" class="facts-fourth__character parallax" data-offset="5">
            </section>
            <img src="../../assets/images/confetti.png" alt="Конфетті " class="confetti parallax" data-offset="-5">
            
            <div class="show-mobile">
              <inline-svg class="pimple pimple-1-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
              <inline-svg class="pimple pimple-3-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
              <inline-svg class="pimple pimple-8-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
              <inline-svg class="pimple pimple-6-sm pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
              <inline-svg class="pimple pimple-7-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
              <inline-svg class="pimple pimple-2-sm pimple-extra-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
              <inline-svg class="pimple pimple-4-sm scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>
              <inline-svg class="pimple pimple-5-sm scar-small-mobile" :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg>
            </div>
            <ScrollToBottom @go-next-section="$emit('go-next-section', 'FactsFifthScreen')" />
        </LayoutFirst>
    </div>
</template>

<script>
import scrollToMixin from '../../mixins/scrollToMixin';
import {throttle, isMobile} from '../../utils/helpers';
import LayoutFirst from '../../components/LayoutFirst';

    export default {
        name: "FactsFourthScreen",
        components: {
          LayoutFirst
        },
        props: {
          navigating: {
            type: Boolean
          }
        },
        mixins: [scrollToMixin],
        mounted() {
          if(!isMobile()) {
            window.addEventListener('mousemove', this.throttledParallax);
          }
        },
        methods: {
          parallaxEffect(e) {
              Array.from(document.querySelectorAll('.parallax')).forEach(el => {
              const offset = el.dataset.offset;
              const x = (window.innerWidth - e.pageX*offset)/100;
              const y = (window.innerHeight - e.pageY*offset)/100;
              el.style.transform = `translate(${x}px, ${y}px)`;
            })
          }
        },
        computed: {
          throttledParallax() {
            return throttle(this.parallaxEffect, 100);
          }
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';
  .pimple {
    @media screen and (max-width: $mobile) {
      &-1-sm {
        right: vwm(106px);
        top: vwm(12px);
      }
      
      &-2-sm {
        right: vwm(149px);
        top: vwm(21px);
      }
      
      &-3-sm {
        right: vwm(123px);
        top: vwm(40px);
      }
      
      &-4-sm {
        right: vwm(33px);
        top: vwm(110px);
      }
      
      &-5-sm {
        right: vwm(54px);
        top: vwm(164px);
      }
      
      &-6-sm {
        left: vwm(20px);
        top: vwm(355px);
      }
      
      &-7-sm {
        left: vwm(52px);
        top: vwm(397px);
      }
      
      &-8-sm {
        left: vwm(16px);
        top: vwm(407px);
      } 
    }
    
  }
    .confetti {
        position: absolute; 
        left: 42.70833vw;
        top: 8.072916vw;
        width: 50vw;
        opacity: 0;
        transition: all 350ms;

        @media screen and (min-width: $minMobile) and (max-height: $maxHeightD) {
          left: 42.7vw;
          top: 0;
        }

        @media screen and (max-width: $mobile) {
            width: 100vw;
            opacity: 1;
            left: 0;
            top: auto;
            bottom: 0;
        }
    }
</style>