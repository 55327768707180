<template functional>
    <div class="section__wrapper">
        <div class="hide-mobile">

            <inline-svg class="pimple pimple-1 scar-middle" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-2 scar-middle" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-3 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-4 scar-right" :src="require('../assets/images/pimples/default/scar-right.svg')"></inline-svg>

            <inline-svg class="pimple pimple-5 scar-large" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-6 scar-regular" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-7 scar-small" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-8 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

            <inline-svg class="pimple pimple-9 pimple-large" :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
            <inline-svg class="pimple pimple-10 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-11 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-12 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

            <inline-svg class="pimple pimple-13 scar-large" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>

            <inline-svg class="pimple pimple-14 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-15 pimple-middle" :src="require('../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>

            <inline-svg class="pimple pimple-16 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-17 pimple-regular" :src="require('../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
            <inline-svg class="pimple pimple-18 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-19 pimple-large" :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
            <inline-svg class="pimple pimple-20 pimple-middle" :src="require('../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>

            <inline-svg class="pimple pimple-21 scar-small" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-22 scar-regular" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>

            <inline-svg class="pimple pimple-23 scar-large" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-24 scar-regular" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-25 scar-small" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-26 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

            <inline-svg class="pimple pimple-27 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
      name: "LayoutSecond"
    }
</script>

<style lang="scss" scoped>
@import '../scss/mixin';
  ::v-deep .pimple {
    &-1 {
      left: vw(173px);
      top: vw(46px);
    }
    
    &-2 {
      left: vw(117px);
      top: vw(138px);
    }
    
    &-3 {
      left: vw(59px);
      top: vw(210px);
    }
    
    &-4 {
      left: vw(224px);
      top: vw(164px);
    }
    
    &-5 {
      left: vw(719px);
      top: vw(125px);
    }
    
    &-6 {
      left: vw(818px);
      top: vw(172px);
    }
    
    &-7 {
      left: vw(686px);
      top: vw(202px);
    }
    
    &-8 {
      left: vw(712px);
      top: vw(221px);
    } 
    
    &-9 {
      right: vw(160px);
      top: vw(70px);
    }
    
    &-10 {
      right: vw(267px);
      top: vw(148px);
    }

    &-11 {
      right: vw(304px);
      top: vw(212px);
    }
    
    &-12 {
      right: vw(167px);
      top: vw(206px);
    }
    
    &-13 {
      left: vw(575px);
      bottom: vw(381px);
    }
    
    &-14 {
      left: vw(71px);
      bottom: vw(301px);
    }
    
    &-15 {
      left: vw(107px);
      bottom: vw(280px);
    }
    
    &-16 {
      left: vw(599px);
      bottom: vw(181px);
    }

    &-17 {
      left: vw(680px);
      bottom: vw(170px);
    }
    
    &-18 {
      left: vw(835px);
      bottom: vw(166px);
    }
    
    &-19 {
      left: vw(450px);
      bottom: vw(50px);
    }
    
    &-20 {
      left: vw(655px);
      bottom: vw(20px);
    }
    
    &-21 {
      right: vw(800px);
      bottom: vw(177px);
    }
    
    &-22 {
      right: vw(636px);
      bottom: vw(173px);
    }

    &-23 {
      right: vw(172px);
      bottom: vw(250px);
    }

    &-24 {
      right: vw(104px);
      bottom: vw(235px);
    }
    
    &-25 {
      right: vw(269px);
      bottom: vw(239px);
    }
    
    &-26 {
      right: vw(181px);
      bottom: vw(162px);
    }

    &-27 {
      right: vw(391px);
      bottom: 0;
    }

  }
</style>