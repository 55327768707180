<template>
    <div class="container" id="first-sept_2" data-section="FirstSeptSecondScreen">
      <div class="hidden-group" v-if="!isMobile()">
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
      </div>
      <LayoutFifth>
        <section class="content-move">
          <div class="fs-second__diary">
            <img :src="require('../../assets/images/fs-2-diary.png')" alt="Вітроля" class="fs-second__diary-book" data-offset="5">
            <img :src="require('../../assets/images/fs-2-diary-table.png')" alt="Вітроля" class="fs-second__diary-table">
            <img :src="require('../../assets/images/fs-2-pen.png')" alt="Вітроля" class="fs-second__diary-pen parallax" data-offset="-5">
          </div>
          <div class="fs-second__content">
            <p class="fs-second__subtitle">
              Шкільні роки — один з найкращих періодів і я<br> намагатимусь, щоб Ваші діти його запам’ятали на все життя! 
            </p>
            <h1 class="bold--accent fs-second__title">
              Хіба що Ви вакцинувались
            </h1>
          </div>
            
        </section>
        <div class="show-mobile">
          <inline-svg class="pimple pimple-1-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-2-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-4-sm pimple-large" :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
          <inline-svg class="pimple pimple-6-sm pimple-large" :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
          <inline-svg class="pimple pimple-7-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-5-sm pimple-extra-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-8-sm pimple-extra-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-3-sm scar-small-mobile" :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
        </div>
        <ScrollToBottom @go-next-section="$emit('go-next-section', 'FirstSeptThirdScreen')" />
      </LayoutFifth>
    </div>
</template>

<script>
import scrollToMixin from '../../mixins/scrollToMixin';
import LayoutFifth from '../../components/LayoutFifth';
import {throttle, isMobile} from '../../utils/helpers';

    export default {
        name: "FirstSeptSecondScreen",
        components: {
          LayoutFifth
        },
        mixins: [scrollToMixin],
        mounted() {
          if(!isMobile()) {
            window.addEventListener('mousemove', this.throttledParallax);
          }
        },
        methods: {
          parallaxEffect(e) {
              Array.from(document.querySelectorAll('.parallax')).forEach(el => {
              const offset = el.dataset.offset;
              const x = (window.innerWidth - e.pageX*offset)/100;
              const y = (window.innerHeight - e.pageY*offset)/100;
              el.style.transform = `translate(${x}px, ${y}px)`;
            })
          }
        },
        computed: {
          throttledParallax() {
            return throttle(this.parallaxEffect, 100);
          }
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';

  .pimple {
    opacity: 1;
    @media screen and (max-width: $mobile) {
      &-1-sm {
        left: vwm(48px);
        top: vwm(17px);
      }
      
      &-2-sm {
        right: vwm(102px);
        top: vwm(436px);
      }
      
      &-3-sm {
        opacity: 1;
        left: vwm(33px);
        top: vwm(68px);
      }
      
      &-4-sm {
        left: vwm(113px);
        top: vwm(9px);
      }
      
      &-5-sm {
        right: vwm(56px);
        bottom: vwm(166px);
      }
      
      &-6-sm {
        right: vwm(20px);
        bottom: vwm(10px);
      }
      
      &-7-sm {
        left: vwm(35px);
        bottom: vwm(80px);
      }
      
      &-8-sm {
        right: vwm(57px);
        bottom: vwm(213px);
      }
    }
    
  }

  .parallax {
    transition: all 350ms;
  }
</style>