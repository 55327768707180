<template>
    <div class="container" id="percentage" data-section="Vaccination">
      <div class="hidden-group" v-if="!isMobile()">
        <inline-svg :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        <inline-svg :src="require('../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
        <inline-svg :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg> 
        <inline-svg :src="require('../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
        <inline-svg :src="require('../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
      </div>
      <LayoutFirst>
          <section class="content-move">
            <div class="vaccination__content">
                <p class="vaccination__description">
                    У багатьох країнах, зокрема й в Україні, щеплення проти вітрянки є рекомендованим, але не входить до переліку обов’язкових. 
                    Дуже зручно для масового зараження!
                </p>
                <p class="bold">
                    Наприклад, у США вакцинацію проти вітряної віспи зробили обов’язковою<sup>1</sup>. 
                </p>
                <p class="vaccination__question">
                    <span class="bold--accent">
                        Як думаєте, на скільки відсотків вони
                    </span>
                    <br>
                    <span class="bold--accent vaccination__marked">
                        скоротили захворюваність у своїй країні?
                    </span>
                </p>
                <div class="vaccination__slider-wrapper" v-if="!showAnswer">
                    <vue-slider v-model="percentage" 
                            :interval="5" 
                            :clickable="false" 
                            tooltip="always" 
                            :processStyle="processStyle"
                            :railStyle="railStyle"
                            class="vaccination__slider"
                            @drag-end="onDragEnd"
                            @change="onChange"
                            @drag-start="onDragStart">
                        <template v-slot:dot>
                            <img src="../assets/images/slider-dot.png" class="Ричаг для управління слайдером" ref="dot" />
                        </template>
                        <template v-slot:tooltip="{ value }">
                            <div class="vaccination__slider-tooltip bold">{{ value }}%</div>
                        </template>
                    </vue-slider>
                    <div class="vaccination__slider-bottom">
                        <span class="vaccination__slider-mark bold">0%</span>
                        <span class="vaccination__slider-mark bold">50%</span>
                        <span class="vaccination__slider-mark bold">100%</span>
                    </div>
                    <img src="../assets/images/vaccination-hand.svg" alt="Указчик на слайдер" class="vaccination__slider-pointer" ref="pointer">
                </div>
                <transition name="fade-up" @after-enter="checkForOverflow">
                    <div class="card vaccination__card" v-if="showAnswer" ref="card">
                        <p>
                            <span v-html="answer"></span>
                            <br>
                            <span class="vaccination__card-marked bold--accent">
                                на неймовірні 70%!<sup>1</sup>
                            </span>
                        </p>
                    </div>
                </transition>
            </div>
            <transition name="fade">
              <img src="../assets/images/vaccination-character.png" alt="Вітроля" class="vaccination__character" ref="character" v-if="!imgOverflow">
            </transition>
        </section>
        <div class="show-mobile">
          <inline-svg class="pimple pimple-1-sm scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>
          <inline-svg class="pimple pimple-2-sm scar-small-mobile" :src="require('../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-3-sm pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-4-sm pimple-extra-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-5-sm pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-6-sm scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>
          <inline-svg class="pimple pimple-7-sm scar-small-mobile" :src="require('../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-8-sm pimple-middle" :src="require('../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-9-sm pimple-extra-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-10-sm pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        </div>
        <ScrollToBottom @go-next-section="$emit('go-next-section', 'TimeFirstScreen')"/>
      </LayoutFirst>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import scrollToMixin from '../mixins/scrollToMixin';
import LayoutFirst from '../components/LayoutFirst';

    export default {
        name: "Vaccination",
        components: {
            VueSlider,
            LayoutFirst
        },
        mixins: [scrollToMixin],
        data() {
            return {
                percentage: 0,
                rightAnswerPercent: 70,
                answer: '',
                showAnswer: false,
                imgOverflow: false
            }
        },
        mounted() {
          this.$refs.dot.addEventListener('mousedown', this.preventFromDragging);
        },
        computed: {
            processStyle() {
                return {
                    'background-color': '#DD9E7F',
                    'border-radius': '2.08vw',
                    'height': '0.208vw',
                    'top': '50%',
                    'transform': 'translateY(-50%)',
                    'left': '0.5%',
                    'max-width': '99%'
                }
            },

            railStyle() {
                return {
                    'background-color': 'transparent',
                    'border-radius': 0,
                    'width': '90%',
                    'left': '5%'
                }
            }
        },
        methods: {
            onDragEnd() {
                if(this.percentage < this.rightAnswerPercent) {
                    this.answer = `Ви справді гадаєте, що вони змогли скоротити захворюванність <span class="bold">всього на ${this.percentage}%?</span> 
                        Повірте, медицина може більше!  Масова вакцинація проти вітряної віспи у США зменшила кількість випадків`;
                }
                else if(this.percentage > this.rightAnswerPercent) {
                    this.answer = `<span class="bold">Ого-го, подивіться на цих оптимістів!</span> Може і в дива віриш? Тим не менш, результат 
                        масової вакцинації проти вітряної віспи переконливий - захворюваність скоротилась`;
                } else {
                    this.answer = `<span class="bold">Яка вражаюча інтуїція, це правильна відповідь!</span> 
                        Масова вакцинація проти вітряної віспи допомогла скоротити захворюваність`;
                }

                this.showAnswer = true;
                this.$refs.dot.removeEventListener('mousedown', this.preventFromDragging);
            },

            onChange(value) {
              if(value > 0) {
                this.$refs.dot.style.transform = `scale(${1 - value / 100 * 0.7})`;
              }
            },

            onDragStart() {
              // eslint-disable-next-line no-undef
              gtag('event', 'click', { 'event_category': 'main_btn_drag'}) 
              this.$refs.pointer.style.animation = 'none';
            },

            preventFromDragging(e) {
              e.preventDefault();
            },

            checkForOverflow() {
              if(window.innerWidth <= 480 && this.$refs.card.getBoundingClientRect().bottom > this.$refs.character.getBoundingClientRect().top) {
                this.imgOverflow = true;
              }
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '../scss/vars';
@import '../scss/mixin';
  .pimple {
    @media screen and (max-width: $mobile) {
      &-1-sm {
        right: vwm(36px);
        top: vwm(95px);
      }
      
      &-2-sm {
        right: vwm(57px);
        top: vwm(149px);
      }
      
      &-3-sm {
        right: vwm(-13px);
        bottom: vwm(191px);
      }
      
      &-4-sm {
        right: vwm(29px);
        bottom: vwm(195px);
      }
      
      &-5-sm {
        right: vwm(3px);
        bottom: vwm(163px);
      }
      
      &-6-sm {
        left: vwm(10px);
        bottom: vwm(114px);
      }
      
      &-7-sm {
        left: vwm(25px);
        bottom: vwm(94px);
      }
      
      &-8-sm {
        left: vwm(75px);
        bottom: vwm(72px);
      } 

      &-9-sm {
        left: vwm(105px);
        bottom: vwm(52px);
      }
      
      &-10-sm {
        left: vwm(69px);
        bottom: vwm(42px);
      } 
    }
  }   
</style>