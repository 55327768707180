<template>
    <div class="container" id="fact_2" data-section="FactsSecondScreen">
      <div class="hidden-group" v-if="!isMobile()">
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
      </div>
      <LayoutSecond>
        <section class="content-move">
            <img src="../../assets/images/facts-2-character.png" alt="Вітроля" class="facts-second__character">
            <div class="card facts__card facts-second__card facts__card-mobile">
              <div class="facts__card-step">
                  2.
              </div>
              <p class="facts__card-text">
                  Обожнюю повітряні поцілунки! Це найромантичніший спосіб заразити вашу малечу, 
                  оскільки я поширююсь повітряно-крапельним шляхом<sup>1</sup>.
              </p>
            </div>
        </section>
        <div class="show-mobile">
          <inline-svg class="pimple pimple-1-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-2-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-3-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-4-sm pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-5-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-6-sm pimple-extra-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-7-sm scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>
          <inline-svg class="pimple pimple-8-sm scar-small-mobile" :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg>
        </div>
        <canvas id="boids" class="content-move"></canvas>
        <ScrollToBottom @go-next-section="$emit('go-next-section', 'FactsThirdScreen')"/>
      </LayoutSecond>
    </div>
</template>

<script>
import scrollToMixin from '../../mixins/scrollToMixin';
import boids from '../../utils/boids';
import LayoutSecond from '../../components/LayoutSecond';

    export default {
        name: 'FactsSecondScreen',
        components: {
          LayoutSecond
        },
        mixins: [scrollToMixin],
        mounted() {
            boids();
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';
  .pimple {
    @media screen and (max-width: $mobile) {
      &-1-sm {
        left: vwm(55px);
        top: vwm(13px);
      }
      
      &-2-sm {
        left: vwm(19px);
        top: vwm(37px);
      }
      
      &-3-sm {
        right: vwm(39px);
        top: vwm(105px);
      }
      
      &-4-sm {
        right: vwm(40px);
        bottom: vwm(200px);
      }
      
      &-5-sm {
        right: vwm(36px);
        bottom: vwm(170px);
      }
      
      &-6-sm {
        right: vwm(70px);
        bottom: vwm(185px);
      }
      
      &-7-sm {
        left: vwm(31px);
        bottom: vwm(52px);
      }
      
      &-8-sm {
        left: vwm(47px);
        bottom: vwm(32px);
      } 
    }
    
  }
    canvas {
        width: 70%;
        height: 100%;
        position: absolute;
        right: 0;
        @media screen and (max-width: $mobile) {
            width: 100%;
            bottom: 0;
            height: 70vh;
        }
    }
</style>