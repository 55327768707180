<template functional>
    <div class="section__wrapper">
        <div class="hide-mobile">

            <inline-svg class="pimple pimple-1 pimple-large" :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
            <inline-svg class="pimple pimple-2 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-3 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-4 scar-left" :src="require('../assets/images/pimples/default/scar-left.svg')"></inline-svg>

            <inline-svg class="pimple pimple-5 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-6 pimple-middle" :src="require('../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>

            <inline-svg class="pimple pimple-7 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-8 pimple-regular" :src="require('../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
            <inline-svg class="pimple pimple-9 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-10 pimple-large" :src="require('../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
            <inline-svg class="pimple pimple-11 pimple-middle" :src="require('../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>

            <inline-svg class="pimple pimple-12 scar-regular" :src="require('../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-13 pimple-small" :src="require('../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
      name: "LayoutFirst"
    }
</script>

<style lang="scss" scoped>
@import '../scss/mixin';
  ::v-deep .pimple {
    &-1 {
      left: vw(85px);
      bottom: vw(25px);
    }
    
    &-2 {
      left: vw(95px);
      bottom: vw(35px);
    }
    
    &-3 {
      left: vw(10px);
      bottom: vw(0px);
    }
    
    &-4 {
      left: vw(105px);
      bottom: vw(-50px);
    }
    
    &-5 {
      left: vw(1030px);
      top: vw(71px);
    }
    
    &-6 {
      left: vw(1060px);
      top: vw(7px);
    }
    
    &-7 {
      right: vw(505px);
      top: vw(355px);
    } 
    
    &-8 {
      right: vw(270px);
      top: vw(38px);
    }
    
    &-9 {
      right: vw(130px);
      top: vw(173px);
    }

    &-10 {
      right: vw(113px);
      top: vw(236px);
    }
    
    &-11 {
      right: vw(455px);
      top: vw(205px);
    }
    
    &-12 {
      right: vw(400px);
      top: vw(261px);
    }
    
    &-13 {
      right: vw(330px);
      bottom: vw(30px);
    }
  }
</style>