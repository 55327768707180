<template>
  <div class="container" id="sm_share" data-section="TimeSecondScreen">
    <div class="hidden-group" v-if="!isMobile()">
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-small.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-large.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-regular.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/pimple-middle.svg')"
      ></inline-svg>
      <inline-svg
        :src="
          require('../../assets/images/pimples/default/pimple-large-mobile.svg')
        "
      ></inline-svg>
      <inline-svg
        :src="
          require('../../assets/images/pimples/default/pimple-middle-mobile.svg')
        "
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar-left.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar-right.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar.svg')"
      ></inline-svg>
      <inline-svg
        :src="require('../../assets/images/pimples/default/scar-small.svg')"
      ></inline-svg>
      <inline-svg
        :src="
          require('../../assets/images/pimples/default/scar-small-mobile.svg')
        "
      ></inline-svg>
    </div>
    <LayoutThird>
      <section class="content-move">
        <picture>
          <source
            srcset="../../assets/images/time-2-character.png"
            media="(min-width: 481px) and (max-height: 768px)"
          />
          <img
            src="../../assets/images/time-2-character-lg.png"
            alt="Вітроля"
            class="time-socials__character"
          />
        </picture>
        <div class="time-socials__title">
          <h6 class="time-socials__heading">
            Добре, що в інтернеті немає щеплень – тут я можу поширюватись
            безкінечно!
          </h6>
          <span class="bold time-socials__subheading">
            А давайте розіграємо ваших друзів?
          </span>
        </div>
        <div class="time-socials__press extra-bold--accent">
          <span>Тисніть, нужбо, давайте:)</span>
          <button
            class="btn btn--orange time-socials__press-btn"
            @click="widgetOpened = true"
          >
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7651 5.54525L13.265 9.79478C12.7906 10.2428 12 9.91063 12 9.24953V7.00069C7.48203 7.031 5.57606 8.09797 6.85078 12.3555C6.99088 12.8234 6.44934 13.1857 6.06934 12.897C4.85162 11.972 3.75 10.2028 3.75 8.41685C3.75 3.91882 7.42497 3.02622 12 3.00085V0.750382C12 0.0886945 12.7912 -0.242368 13.265 0.205132L17.7651 4.45476C18.0782 4.75047 18.0784 5.24929 17.7651 5.54525ZM12 11.8478V14H2V4.00001H3.59113C3.64162 3.99997 3.69159 3.98973 3.73804 3.9699C3.78448 3.95007 3.82643 3.92105 3.86138 3.8846C4.32866 3.3981 4.86878 3.01304 5.45556 2.70722C5.80338 2.52594 5.6745 2.00001 5.28228 2.00001H1.5C0.671562 2.00001 0 2.67157 0 3.50001V14.5C0 15.3284 0.671562 16 1.5 16H12.5C13.3284 16 14 15.3284 14 14.5V11.7248C14 11.4658 13.7438 11.2853 13.4997 11.3716C13.1573 11.4927 12.7907 11.5289 12.4312 11.4772C12.2041 11.4445 12 11.6182 12 11.8478Z"
                fill="white"
              />
            </svg>
            Заразити соцмережі!
          </button>
        </div>
        <a
          :href="twitterShareHref"
          class="time-socials__icon-tw time-socials__icon"
          target="_blank"
          @click="openPopup(twitterShareHref, 'twitter-share'), twitterShare()"
        >
          <svg
            width="108"
            height="99"
            viewBox="0 0 108 99"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M101.069 16.9756C96.7174 8.09705 84.1811 0.832796 69.5987 5.06221C62.6305 7.06307 56.5509 11.354 52.3531 17.2339C48.1553 11.354 42.0757 7.06307 35.1076 5.06221C20.4927 0.897367 7.98884 8.09705 3.63685 16.9756C-2.46892 29.4055 0.0643258 43.3852 11.1716 58.5271C19.8756 70.3759 32.3145 82.3862 50.372 96.3336C50.9426 96.776 51.6456 97.0163 52.3693 97.0163C53.0931 97.0163 53.796 96.776 54.3667 96.3336C72.3917 82.4185 84.8631 70.5051 93.5671 58.5271C104.642 43.3852 107.175 29.4055 101.069 16.9756Z"
              fill="#DC9375"
            />
            <path
              d="M101.069 16.9756C96.7174 8.09705 84.1811 0.832796 69.5987 5.06221C62.6305 7.06307 56.5509 11.354 52.3531 17.2339C48.1553 11.354 42.0757 7.06307 35.1076 5.06221C20.4927 0.897367 7.98884 8.09705 3.63685 16.9756C-2.46892 29.4055 0.0643258 43.3852 11.1716 58.5271C19.8756 70.3759 32.3145 82.3862 50.372 96.3336C50.9426 96.776 51.6456 97.0163 52.3693 97.0163C53.0931 97.0163 53.796 96.776 54.3667 96.3336C72.3917 82.4185 84.8631 70.5051 93.5671 58.5271C104.642 43.3852 107.175 29.4055 101.069 16.9756Z"
              fill="url(#paint0_linear)"
            />
            <g filter="url(#filter0_f)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M69.6858 4.2375C84.2682 0.00808879 96.8045 7.27234 101.156 16.1509C107.044 28.1367 104.899 41.5635 94.8085 56.0839C103.468 42.5107 105.059 29.9274 99.507 18.6253C95.1551 9.74681 82.6187 2.48256 68.0363 6.71197C61.0682 8.71283 54.9886 13.0037 50.7908 18.8836C46.593 13.0037 40.5134 8.71283 33.5452 6.71197C20.8698 3.09981 9.78228 8.0361 4.22363 15.2021C8.97599 6.77029 21.1042 0.222105 35.1946 4.2375C42.1628 6.23836 48.2424 10.5293 52.4402 16.4092C56.638 10.5293 62.7176 6.23836 69.6858 4.2375Z"
                fill="#FFF6EF"
              />
            </g>
            <g filter="url(#filter1_f)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.9756 59.5929C21.875 70.0313 33.6567 80.824 49.3902 92.9795C50.0061 93.4572 50.7649 93.7166 51.5461 93.7166C52.3272 93.7166 53.086 93.4572 53.702 92.9795C73.1575 77.9561 86.6187 65.0939 96.0135 52.1619C98.8155 48.3299 101.111 44.5667 102.899 40.8779C101.166 46.3018 98.182 51.9169 93.951 57.7017C85.247 69.6796 72.7757 81.593 54.7506 95.5081C54.18 95.9506 53.477 96.1909 52.7533 96.1909C52.0295 96.1909 51.3266 95.9506 50.7559 95.5081C33.6583 82.3022 21.5979 70.8329 12.9756 59.5929Z"
                fill="#DC9586"
              />
            </g>
            <g filter="url(#filter2_f)">
              <path
                d="M71.3665 40.6049C70.1099 41.1695 68.7425 41.5759 67.3339 41.7341C68.7963 40.8292 69.8914 39.3997 70.4138 37.7136C69.0416 38.5639 67.5382 39.1601 65.9706 39.4758C65.3154 38.7462 64.5229 38.165 63.6426 37.7684C62.7623 37.3718 61.813 37.1682 60.8539 37.1704C56.9732 37.1704 53.8523 40.4467 53.8523 44.4672C53.8523 45.0318 53.918 45.5963 54.0248 46.1395C48.214 45.823 43.0316 42.9317 39.5863 38.5049C38.9585 39.6217 38.6295 40.8933 38.6336 42.1875C38.6336 44.7195 39.8696 46.9522 41.7545 48.2653C40.6437 48.2197 39.5589 47.9017 38.5884 47.3371V47.4269C38.5884 50.9727 40.9948 53.911 44.202 54.5868C43.5998 54.7497 42.9803 54.8331 42.3582 54.8349C41.9023 54.8349 41.4712 54.7878 41.0359 54.7237C41.9229 57.615 44.5059 59.7151 47.5816 59.7835C45.1752 61.7467 42.1611 62.9015 38.8882 62.9015C38.3009 62.9015 37.7589 62.8801 37.1963 62.8117C40.3008 64.8861 43.9843 66.0837 47.9512 66.0837C60.8292 66.0837 67.876 54.9718 67.876 45.3269C67.876 45.0104 67.876 44.6939 67.8555 44.3774C69.2188 43.338 70.4138 42.0506 71.3665 40.6049Z"
                fill="#D89079"
              />
            </g>
            <path
              class="social-logo"
              d="M70.0521 39.2905C68.7955 39.8551 67.428 40.2614 66.0195 40.4197C67.4819 39.5148 68.577 38.0852 69.0994 36.3992C67.7271 37.2494 66.2238 37.8456 64.6561 38.1613C64.0009 37.4318 63.2085 36.8506 62.3282 36.4539C61.4479 36.0573 60.4986 35.8538 59.5394 35.856C55.6588 35.856 52.5378 39.1322 52.5378 43.1527C52.5378 43.7173 52.6035 44.2819 52.7103 44.8251C46.8996 44.5086 41.7172 41.6172 38.2718 37.1904C37.644 38.3073 37.3151 39.5789 37.3191 40.873C37.3191 43.4051 38.5552 45.6377 40.4401 46.9508C39.3293 46.9052 38.2445 46.5872 37.2739 46.0227V46.1125C37.2739 49.6582 39.6804 52.5966 42.8875 53.2724C42.2854 53.4353 41.6659 53.5186 41.0437 53.5204C40.5879 53.5204 40.1567 53.4734 39.7214 53.4092C40.6084 56.3006 43.1914 58.4006 46.2672 58.4691C43.8608 60.4322 40.8466 61.5871 37.5737 61.5871C36.9865 61.5871 36.4444 61.5657 35.8818 61.4972C38.9864 63.5716 42.6699 64.7692 46.6368 64.7692C59.5148 64.7692 66.5615 53.6573 66.5615 44.0124C66.5615 43.6959 66.5615 43.3794 66.541 43.0629C67.9044 42.0236 69.0994 40.7362 70.0521 39.2905Z"
              fill="white"
            />
            <defs>
              <filter
                id="filter0_f"
                x="1.59515"
                y="0.37152"
                width="105.431"
                height="58.3409"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1.31424"
                  result="effect1_foregroundBlur"
                />
              </filter>
              <filter
                id="filter1_f"
                x="10.3471"
                y="38.2494"
                width="95.1799"
                height="60.5699"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1.31424"
                  result="effect1_foregroundBlur"
                />
              </filter>
              <filter
                id="filter2_f"
                x="35.882"
                y="35.8562"
                width="36.7987"
                height="31.5418"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="0.65712"
                  result="effect1_foregroundBlur"
                />
              </filter>
              <linearGradient
                id="paint0_linear"
                x1="23.4892"
                y1="11.0154"
                x2="77.8067"
                y2="92.9038"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E7BB9A" />
                <stop offset="1" stop-color="#FFE3CE" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </a>
        <a
          :href="facebookShareHref"
          class="time-socials__icon-fb time-socials__icon"
          target="_blank"
          @click="
            openPopup(facebookShareHref, 'facebook-share'), facebookShare()
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="163"
            height="150"
            viewBox="0 0 163 150"
            fill="none"
          >
            <path
              d="M155.265 24.5544C148.553 10.8611 129.218 -0.34241 106.728 6.18055C95.9811 9.26645 86.6046 15.8843 80.1305 24.9527C73.6563 15.8843 64.2798 9.26645 53.5329 6.18055C30.9926 -0.242823 11.7081 10.8611 4.99607 24.5544C-4.42078 43.7249 -0.51379 65.2856 16.6169 88.6388C30.0409 106.913 49.2252 125.436 77.075 146.947C77.9551 147.629 79.0393 148 80.1555 148C81.2717 148 82.3559 147.629 83.236 146.947C111.036 125.486 130.27 107.112 143.694 88.6388C160.775 65.2856 164.682 43.7249 155.265 24.5544Z"
              fill="#DC9375"
            />
            <path
              d="M155.265 24.5544C148.553 10.8611 129.218 -0.34241 106.728 6.18055C95.9811 9.26645 86.6046 15.8843 80.1305 24.9527C73.6563 15.8843 64.2798 9.26645 53.5329 6.18055C30.9926 -0.242823 11.7081 10.8611 4.99607 24.5544C-4.42078 43.7249 -0.51379 65.2856 16.6169 88.6388C30.0409 106.913 49.2252 125.436 77.075 146.947C77.9551 147.629 79.0393 148 80.1555 148C81.2717 148 82.3559 147.629 83.236 146.947C111.036 125.486 130.27 107.112 143.694 88.6388C160.775 65.2856 164.682 43.7249 155.265 24.5544Z"
              fill="url(#paint0_linear)"
            />
            <g filter="url(#filter0_f)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M106.863 4.90858C129.353 -1.61438 148.688 9.58918 155.4 23.2824C164.481 41.769 161.172 62.4781 145.607 84.874C158.965 63.9387 161.419 44.5305 152.856 27.0985C146.144 13.4052 126.809 2.20167 104.319 8.72463C93.5722 11.8105 84.1957 18.4283 77.7216 27.4968C71.2474 18.4283 61.8709 11.8105 51.124 8.72463C31.5753 3.15379 14.4756 10.7665 5.90234 21.818C13.2323 8.8143 31.937 -1.28413 53.6679 4.90858C64.4148 7.99448 73.7913 14.6123 80.2655 23.6808C86.7396 14.6123 96.1161 7.99448 106.863 4.90858Z"
                fill="#FFF6EF"
              />
            </g>
            <g filter="url(#filter1_f)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.4004 90.2861C33.1255 106.384 51.2957 123.029 75.5602 141.775C76.5102 142.512 77.6804 142.912 78.8852 142.912C80.0899 142.912 81.2602 142.512 82.2102 141.775C112.216 118.605 132.977 98.7679 147.467 78.8231C151.788 72.9131 155.328 67.1094 158.085 61.4204C155.413 69.7854 150.811 78.4453 144.286 87.3668C130.862 105.84 111.627 124.214 83.8275 145.675C82.9474 146.357 81.8632 146.728 80.747 146.728C79.6308 146.728 78.5466 146.357 77.6665 145.675C51.2984 125.309 32.6983 107.621 19.4004 90.2861Z"
                fill="#DC9586"
              />
            </g>
            <g filter="url(#filter2_f)">
              <path
                d="M75.8006 100.939V78.2594H69.9561V70.0937H75.8006V63.1192C75.8006 57.6385 79.2529 52.6055 87.2079 52.6055C90.4288 52.6055 92.8104 52.9223 92.8104 52.9223L92.6228 60.5477C92.6228 60.5477 90.1938 60.5234 87.5433 60.5234C84.6746 60.5234 84.215 61.8799 84.215 64.1313V70.0937H92.8508L92.475 78.2594H84.215V100.939H75.8006Z"
                fill="#D89079"
              />
            </g>
            <path
              class="social-logo"
              d="M74.5291 99.6663V76.9869H68.6846V68.8213H74.5291V61.8467C74.5291 56.3661 77.9814 51.333 85.9364 51.333C89.1573 51.333 91.5389 51.6498 91.5389 51.6498L91.3513 59.2752C91.3513 59.2752 88.9224 59.2509 86.2718 59.2509C83.4031 59.2509 82.9435 60.6074 82.9435 62.8588V68.8213H91.5793L91.2036 76.9869H82.9435V99.6663H74.5291Z"
              fill="white"
            />
            <defs>
              <filter
                id="filter0_f"
                x="3.35848"
                y="0.45614"
                width="159.584"
                height="86.9617"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1.27193"
                  result="effect1_foregroundBlur"
                />
              </filter>
              <filter
                id="filter1_f"
                x="16.8565"
                y="58.8766"
                width="143.772"
                height="90.3954"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1.27193"
                  result="effect1_foregroundBlur"
                />
              </filter>
              <filter
                id="filter2_f"
                x="68.6841"
                y="51.3335"
                width="25.4386"
                height="50.8772"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="0.635965"
                  result="effect1_foregroundBlur"
                />
              </filter>
              <linearGradient
                id="paint0_linear"
                x1="35.614"
                y1="15.3621"
                x2="119.387"
                y2="141.657"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E7BB9A" />
                <stop offset="1" stop-color="#FFE3CE" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </a>
      </section>
      <div class="show-mobile">
        <inline-svg
          class="pimple pimple-1-sm pimple-middle"
          :src="
            require('../../assets/images/pimples/default/pimple-middle-mobile.svg')
          "
        ></inline-svg>
        <inline-svg
          class="pimple pimple-2-sm pimple-small"
          :src="require('../../assets/images/pimples/default/pimple-small.svg')"
        ></inline-svg>
        <inline-svg
          class="pimple pimple-3-sm pimple-small"
          :src="require('../../assets/images/pimples/default/pimple-small.svg')"
        ></inline-svg>
        <inline-svg
          class="pimple pimple-4-sm pimple-small"
          :src="require('../../assets/images/pimples/default/pimple-small.svg')"
        ></inline-svg>
        <inline-svg
          class="pimple pimple-5-sm pimple-extra-small"
          :src="require('../../assets/images/pimples/default/pimple-small.svg')"
        ></inline-svg>
        <inline-svg
          class="pimple pimple-6-sm pimple-small"
          :src="require('../../assets/images/pimples/default/pimple-small.svg')"
        ></inline-svg>
      </div>
      <ScrollToBottom
        @go-next-section="$emit('go-next-section', 'RiskGroupsFirstScreen')"
      />
      <transition name="fade">
        <Overlay :show="widgetOpened">
          <Widget @click="closePopup" />
        </Overlay>
      </transition>
    </LayoutThird>
  </div>
</template>

<script>
import scrollToMixin from "../../mixins/scrollToMixin";
import Overlay from "../../components/Overlay";
import Widget from "../../components/Widget";
import LayoutThird from "../../components/LayoutThird";

export default {
  name: "TimeSecondScreen",
  mixins: [scrollToMixin],
  components: {
    Overlay,
    Widget,
    LayoutThird,
  },
  data() {
    return {
      widgetOpened: false,
      location: "",
      twitterText:
        "Вітроля розкаже все про вітрянку: причини, загрози, групи ризику та вакцинацію. Дізайтеся більше, щоб Вітроля не завітала в гості до вашої родини!",
    };
  },
  created() {
    this.location = location;
  },
  computed: {
    twitterShareHref() {
      return `https://twitter.com/share?text=${this.twitterText}?&url=${this.location}`;
    },

    facebookShareHref() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.location}`;
    },
  },
  methods: {
    closePopup() {
      this.widgetOpened = false;
    },

    openPopup(href, title) {
      window.open(href, title, "width=580,height=340");
      return false;
    },
    twitterShare() {
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "main_btn_twitter" });
    },
    facebookShare() {
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "main_btn_facebook" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/vars";
@import "../../scss/mixin";
.pimple {
  @media screen and (max-width: $mobile) {
    &-1-sm {
      right: vwm(88px);
      top: vwm(82px);
    }

    &-2-sm {
      right: vwm(75px);
      top: vwm(119px);
    }

    &-3-sm {
      right: vwm(111px);
      top: vwm(129px);
    }

    &-4-sm {
      left: vwm(54px);
      top: vwm(315px);
    }

    &-5-sm {
      left: vwm(24px);
      top: vwm(324px);
    }

    &-6-sm {
      left: vwm(37px);
      top: vwm(343px);
    }
  }
}
</style>
