<template>
    <div class="container" data-section="FirstSeptThirdScreen" id="first-sept_3">
      <div class="hidden-group" v-if="!isMobile()">
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
        <inline-svg :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
        <inline-svg :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
      </div>
        <LayoutSixth>
          <section class="content-move">
            <img :src="require('../../assets/images/fs-3-character.png')" alt="Вітроля" class="fs-third__character">
            <div class="fs-third__content">
              <h1 class="bold--accent fs-third__title">
                  Вакцинація не залишає для мене шансів
              </h1>
              <p class="bold fs-third__subtitle">
                Вакцинація проти вірусу вітряної віспи ефективна у <span>94,4%</span> випадків після введення 1 дози і у <span>98,3%</span> після введення 2 дози<sup>1</sup>
              </p>
              <div class="fs-third__tables">
                <div class="fs-third__table">
                  <div class="fs-third__all-vac">
                    <div class="fs-third__table-top">
                      <div class="fs-third__tab">
                        Кір, паротит, краснуха
                      </div>
                      <div class="fs-third__tab">
                        Дифтерія, правець
                      </div>
                      <div class="fs-third__tab">
                        Поліомієліт
                      </div>
                    </div>
                    <div class="fs-third__table-bottom">
                      <strong>6 років</strong>
                      <p>Рекомендовані щеплення у дошкільному<br> віці за календарем<sup>2</sup></p>
                    </div>
                  </div>
                  <div class="fs-third__right-sec">
                    <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 20L39 20" stroke="#F3634E" stroke-width="5" stroke-linecap="round"/>
                      <path d="M3 20L39 20" stroke="#F3634E" stroke-width="5" stroke-linecap="round"/>
                      <line x1="20.5" y1="2.5" x2="20.5" y2="37.5" stroke="#F3634E" stroke-width="5" stroke-linecap="round"/>
                      <line x1="20.5" y1="2.5" x2="20.5" y2="37.5" stroke="#F3634E" stroke-width="5" stroke-linecap="round"/>
                    </svg>
                    <div class="fs-third__vitr-vac">
                      <div class="fs-third__table-top">
                        Вітряна віспа
                      </div>
                      <div class="fs-third__table-bottom">
                        <strong>Від 9 місяців</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fs-third__subtable">
                  <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.0359 5.99999C16.5755 3.33333 20.4245 3.33333 21.9641 6L31.0574 21.75C32.597 24.4167 30.6725 27.75 27.5933 27.75H9.40673C6.32753 27.75 4.40303 24.4167 5.94263 21.75L15.0359 5.99999Z" fill="white"/>
                    <path d="M18.8235 20.3084C18.6317 20.3084 18.4654 20.2444 18.3247 20.1165C18.1968 19.9886 18.1201 19.8032 18.0945 19.5602L17.3847 12.0971L17.3655 11.9053C17.3655 11.5088 17.4998 11.1954 17.7684 10.9652C18.0497 10.7222 18.4015 10.6007 18.8235 10.6007C19.2712 10.6007 19.6357 10.7414 19.9171 11.0228C20.1985 11.2914 20.3136 11.6559 20.2624 12.1163L19.5526 19.5602C19.527 19.8032 19.4439 19.9886 19.3032 20.1165C19.1753 20.2444 19.0154 20.3084 18.8235 20.3084ZM18.8235 24.3373C18.4143 24.3373 18.0753 24.203 17.8067 23.9344C17.5381 23.653 17.4038 23.3077 17.4038 22.8984C17.4038 22.4891 17.5381 22.1502 17.8067 21.8816C18.0753 21.6002 18.4143 21.4595 18.8235 21.4595C19.2328 21.4595 19.5718 21.6002 19.8404 21.8816C20.109 22.1502 20.2432 22.4891 20.2432 22.8984C20.2432 23.3077 20.109 23.653 19.8404 23.9344C19.5718 24.203 19.2328 24.3373 18.8235 24.3373Z" fill="#1D9E9E"/>
                  </svg>
                  Щеплення проти вітряної віспи може проводитись разом з іншими плановими щепленнями.
                </div>
              </div>
              <p class="fs-third__text">
                Якщо наша зустріч відміняється, то… 
                Зверніться до Вашого лікаря для отримання більш детальної інформації про захворювання та вакцинацію.
              <!-- <a
                class="btn side-menu__btn btn--orange btn--newStyle  side-menu__newStyle fs-third__btn"
                href="https://vakcynacia.com.ua/ua/map"
                target="_blank"
                @click="btnVaccineClick()">

                <svg width="22" height="22" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5976 5.02702C13.8876 2.79279 17.1124 2.79279 18.4024 5.02703L26.021 18.223C27.311 20.4572 25.6986 23.25 23.1187 23.25H7.88131C5.30144 23.25 3.68903 20.4572 4.97896 18.223L12.5976 5.02702Z" fill="white"/>
                  <path d="M15.771 16.6098C15.6102 16.6098 15.4709 16.5562 15.353 16.449C15.2459 16.3419 15.1816 16.1865 15.1601 15.9829L14.5654 9.73009L14.5493 9.56935C14.5493 9.23715 14.6618 8.9746 14.8869 8.78172C15.1226 8.57811 15.4173 8.47631 15.771 8.47631C16.146 8.47631 16.4514 8.59418 16.6872 8.82994C16.9229 9.05497 17.0194 9.36038 16.9765 9.74616L16.3818 15.9829C16.3603 16.1865 16.2907 16.3419 16.1728 16.449C16.0656 16.5562 15.9317 16.6098 15.771 16.6098ZM15.771 19.9853C15.428 19.9853 15.1441 19.8728 14.919 19.6478C14.694 19.412 14.5815 19.1227 14.5815 18.7798C14.5815 18.4369 14.694 18.1529 14.919 17.9279C15.1441 17.6921 15.428 17.5742 15.771 17.5742C16.1139 17.5742 16.3978 17.6921 16.6229 17.9279C16.8479 18.1529 16.9604 18.4369 16.9604 18.7798C16.9604 19.1227 16.8479 19.412 16.6229 19.6478C16.3978 19.8728 16.1139 19.9853 15.771 19.9853Z" fill="#F05745"/>
                </svg>

                <span class="new-text"><span class="big">ОБЕРЕЖНО!</span><br>Інформація про щеплення</span>

              </a> -->
                <a
                  class="btn btn--orange fs-third__btn"
                  href="https://vakcynacia.com.ua/ua/map"
                  target="_blank"
                  @click="btnVaccineClick()"
                >
                  Мої щеплення
                  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 6.09111C0.498032 6.09111 0.0911068 6.49803 0.0911068 7C0.0911067 7.50197 0.498032 7.90889 1 7.90889L1 6.09111ZM19.6427 7.64269C19.9976 7.28774 19.9976 6.71226 19.6427 6.35732L13.8585 0.573156C13.5036 0.21821 12.9281 0.21821 12.5732 0.573156C12.2182 0.9281 12.2182 1.50358 12.5732 1.85852L17.7146 7L12.5732 12.1415C12.2182 12.4964 12.2182 13.0719 12.5732 13.4268C12.9281 13.7818 13.5036 13.7818 13.8585 13.4268L19.6427 7.64269ZM1 7.90889L19 7.90889L19 6.09111L1 6.09111L1 7.90889Z" fill="white"/>
                  </svg>
                </a>
              </p>
            </div>
          </section>
            <div class="show-mobile">
              <inline-svg class="pimple pimple-1-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
              <inline-svg class="pimple pimple-2-sm pimple-extra-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
              <inline-svg class="pimple pimple-4-sm pimple-large" :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
              <inline-svg class="pimple pimple-3-sm scar-small-mobile" :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
            </div>
            <ScrollToBottom @go-next-section="$emit('go-next-section', 'FactsSixthScreen')" />
        </LayoutSixth>
    </div>
</template>

<script>
import scrollToMixin from '../../mixins/scrollToMixin';
import LayoutSixth from '../../components/LayoutSixth';

    export default {
        name: "FirstSeptThirdScreen",
        components: {
          LayoutSixth
        },
        props: {
          navigating: {
            type: Boolean
          }
        },
        mixins: [scrollToMixin],
        methods: {
          btnVaccineClick() {
            gtag("event", "click", {
              event_category: "Кнопка 'Мої щеплення'",
              event_label: '1-ше вірусня'
            });
          }
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';
  .pimple {
    @media screen and (max-width: $mobile) {
      &-1-sm {
        right: vwm(5px);
        bottom: vwm(59px);
      }
      
      &-2-sm {
        left: vwm(46px);
        top: vwm(5px);
      }
      
      &-3-sm {
        left: vwm(73px);
        top: vwm(6px);
      }
      
      &-4-sm {
        right: vwm(-45px);
        bottom: vwm(78px);
      }
    }
    
  }
</style>