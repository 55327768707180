<template>
    <div class="overlay" v-if="show">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Overlay",
    props: {
        show: Boolean
    },
    watch: {
      show(isShown) {
        if(isShown) {
            document.body.classList.add('overlay-active');
        } else {
            document.body.classList.remove('overlay-active');
        }
      }
    }
}
</script>