<template>
    <div class="container">
        <section class="content-move">
            <article class="history__about">
                <p>
                    Мені завжди казали, що я схожа на «маму» – натуральну віспу. Уявляєте, до кінця ХІХ 
                    століття люди взагалі нас не відрізняли та вважали однією хворобою<sup>2</sup>!
                </p>
                <p>
                    Натуральна віспа була безжальною. За приблизними підрахунками з VI по ХХ століття 
                    вона призвела до смерті щонайменше 1 мільярда людей. Все змінилось, коли 14 травня 1796 
                    року Едвард Дженнер створив першу в світі вакцину – саме проти натуральної віспи.
                </p>
                <p>
                    Моя «мама» боролась, але глобальна вакцинація ВООЗ привела до повної ліквідації натуральної віспи у 1980 році. Її більше не існує…
                </p>
                <p>
                    Та досить про сумне, я ж досі процвітаю! У 1875 році Рудольф Стейнер довів, що вітряна віспа передається через рідину, яка міститься в пухирцях<sup>2</sup>.
                </p>
                <p>
                    У 1888 році Янош вон Бокай виявив, що оперізуючий герпес та вітряна віспа 
                    спричиняються одним і тим же збудником – у дітей, які контактували з хворим на оперізуючий герпес, починалася вітрянка<sup>2</sup>.
                </p>
                <p>
                    Нарешті, у 1954 році я стала відомою на весь світ – Томас Веллер виділив вірус вітряної віспи<sup>2</sup>.
                </p>
                <p class="history__marked-wrap">
                    Проблеми з’явились у 1970 році, коли
                    <span class="history__marked extra-bold--accent">
                        в Японії створили першу вакцину проти вітряної віспи
                    </span>
                    і зі мною почали боротись<sup>2</sup>.
                </p>
                <p>
                    На щастя, історія моєї «мами» не повторюється зі мною – вакцинацію проти вітрянки поки що не відносять до обов’язкових
                    в багатьох країнах. Отже, я можу і далі інфікувати людей за невеликим виключенням тих, хто добровільно вакцинувався.
                </p>
            </article>
            <div class="history__character">
                <div class="history__character-inner">
                    <img src="../../assets/images/history-1-character.png" alt="Вітроля">
                    <div>
                        <h6 class="bold">
                            Знаєте, ви мені подобаєтесь, тому я не хочу, щоб між нами були таємниці.
                        </h6>
                        <h5 class="extra-bold--accent">
                            Я розкажу свою історію.
                        </h5>
                    </div>
                </div>
            </div>
        </section>
        <div class="hidden-group" v-if="!isMobile()">
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-regular.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-large-mobile.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
          <inline-svg :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg> 
          <inline-svg :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg> 
          <inline-svg :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg> 
          <inline-svg :src="require('../../assets/images/pimples/default/scar-small.svg')"></inline-svg> 
          <inline-svg :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg> 
        </div>
        <div class="hide-mobile">

            <inline-svg class="pimple pimple-1 pimple-large" :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
            <inline-svg class="pimple pimple-2 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-3 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-4 scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>

            <inline-svg class="pimple pimple-5 scar-small" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-6 scar-regular" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
            
            <inline-svg class="pimple pimple-7 pimple-large" :src="require('../../assets/images/pimples/default/pimple-large.svg')"></inline-svg>
            <inline-svg class="pimple pimple-8 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-9 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-10 scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>

            <inline-svg class="pimple pimple-11 scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>
            
            <inline-svg class="pimple pimple-12 scar-middle" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-13 scar-middle" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-14 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-15 scar-right" :src="require('../../assets/images/pimples/default/scar-right.svg')"></inline-svg>
            
            <inline-svg class="pimple pimple-16 scar-large" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-17 scar-regular" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-18 scar-small" :src="require('../../assets/images/pimples/default/scar.svg')"></inline-svg>
            <inline-svg class="pimple pimple-19 scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>
            
            <inline-svg class="pimple pimple-20 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            <inline-svg class="pimple pimple-21 pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
            
            <inline-svg class="pimple pimple-22 pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
            
        </div>
        <div class="show-mobile">
          <inline-svg class="pimple pimple-1-sm scar-left" :src="require('../../assets/images/pimples/default/scar-left.svg')"></inline-svg>
          <inline-svg class="pimple pimple-2-sm scar-small-mobile" :src="require('../../assets/images/pimples/default/scar-small-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-3-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-4-sm pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle.svg')"></inline-svg>
          <inline-svg class="pimple pimple-5-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-6-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-7-sm pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-8-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-9-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>
          <inline-svg class="pimple pimple-10-sm pimple-middle" :src="require('../../assets/images/pimples/default/pimple-middle-mobile.svg')"></inline-svg>
          <inline-svg class="pimple pimple-11-sm pimple-small" :src="require('../../assets/images/pimples/default/pimple-small.svg')"></inline-svg>  
        </div>
        <ScrollToBottom @go-next-section="goLastSection" class="history-scroll-bottom" />
    </div>
</template>

<script>
import scrollToMixin from '../../mixins/scrollToMixin';

    export default {
        name: "HistoryFirstScreen",
        mixins: [scrollToMixin],
        props: {
          speed: {
            type: Number
          }
        },
        data() {
          return {
            scrolled: false
          }
        },
        methods: {
          goLastSection() {
            document.getElementById('historyLast').scrollIntoView({behavior: 'smooth'});
          }
        }
    }
</script>

<style lang="scss" scoped>
@import '../../scss/vars';
@import '../../scss/mixin';
.container {
  position: relative !important;
}
  .pimple {
    &-1 {
      left: vw(55px);
      top: vw(45px);
    }
    
    &-2 {
      left: vw(60px);
      top: vw(120px);
    }
    
    &-3 {
      left: vw(30px);
      top: vw(185px);
    }
    
    &-4 {
      left: vw(95px);
      top: vw(179px);
    }
    
    &-5 {
      right: vw(953px);
      top: vw(120px);
    }
    
    &-6 {
      right: vw(834px);
      top: vw(86px);
    }
    
    &-7 {
      right: vw(590px);
      top: 0;
    }
    
    &-8 {
      right: vw(694px);
      top: vw(78px);
    } 
    
    &-9 {
      right: vw(731px);
      top: vw(142px);
    }
    
    &-10 {
      right: vw(614px);
      top: vw(136px);
    }

    &-11 {
      right: vw(543px);
      top: vw(271px);
    }
    
    &-12 {
      right: vw(223px);
      top: vw(182px);
    }
    
    &-13 {
      right: vw(285px);
      top: vw(274px);
    }
    
    &-14 {
      right: vw(388px);
      top: vw(346px);
    }
    
    &-15 {
      right: vw(160px);
      top: vw(300px);
    }
    
    &-16 {
      left: vw(268px);
      bottom: vw(302px);
    }

    &-17 {
      left: vw(367px);
      bottom: vw(287px);
    }
    
    &-18 {
      left: vw(235px);
      bottom: vw(291px);
    }
    
    &-19 {
      left: vw(261px);
      bottom: vw(214px);
    }
    
    &-20 {
      right: vw(554px);
      bottom: vw(323px);
    }
    
    &-21 {
      right: vw(432px);
      bottom: vw(306px);
    }
    
    &-22 {
      right: vw(33px);
      bottom: vw(213px);
    }

    @media screen and (max-width: $mobile) {
      &-1-sm {
        left: vwm(28px);
        top: vwm(15px);
      }
      
      &-2-sm {
        left: vwm(43px);
        top: vwm(69px);
      }
      
      &-3-sm {
        left: vwm(35px);
        top: vwm(98px);
      }
      
      &-4-sm {
        left: vwm(30px);
        top: vwm(132px);
      }
      
      &-5-sm {
        left: vwm(6px);
        top: vwm(122px);
      }
      
      &-6-sm {
        right: vwm(76px);
        top: vwm(388px);
      }
      
      &-7-sm {
        right: vwm(85px);
        top: vwm(412px);
      }
      
      &-8-sm {
        right: vwm(51px);
        top: vwm(416px);
      } 

      &-9-sm {
        right: vwm(40px);
        bottom: vwm(276px);
      }
      
      &-10-sm {
        right: vwm(32px);
        bottom: vwm(225px);
      }
      
      &-11-sm {
        right: vwm(73px);
        bottom: vwm(250px);
      } 
    }
  }  
    section {
        height: auto;
        overflow: visible;
        @media screen and (max-width: $mobile) {
            display: flex;
            flex-direction: column-reverse;
            padding-top: vwm(60px);
        }
    }

    .container {
        height: auto;
        min-height: 100vh;
    }
</style>